import { Grid } from "@material-ui/core";
import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { PostData } from "../../api/service";

const handleDragStart = (e) => e.preventDefault();

export default function AchievementsSmall() {
  const [readMore, setReadMore] = React.useState(false);

  const [data, setData] = React.useState([]);
  console.log("🚀 ~ Testimonials ~ data:", data);
  const [isLoaded, setIsLoaded] = React.useState(false);

  function __loadData() {
    let d = {
      dept_code: "PANDU",
      type: "COLLEGE_ACHIEVEMENTS_SINGLE",
    };

    PostData(`/getdepartmentfilebytype`, d).then((resp) => {
      resp.map((el, index) => {
        let haveMore = null;
        // check the length
        let str = removeTags(el.description);
        if (str.length > 400) {
          haveMore = true;
        } else {
          haveMore = null;
        }

        if (haveMore == true) {
          resp[index]["haveMore"] = true;
          resp[index]["showMore"] = false;
          resp[index]["str"] = str;
        }
      });

      setData([...resp]);
      setIsLoaded(true);
    });
  }

  React.useEffect(() => {
    __loadData();
  }, []);

  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  return (
    <div>
      <AliceCarousel
        syncStateOnPropsUpdate={false}
        responsive={{
          0: {
            items: 1,
          },
          1024: {
            items: 2,
            itemsFit: "contain",
          },
        }}
        activeIndex={2}
        autoPlay={true}
        autoPlayInterval={3000}
        mouseTracking
        items={data.map((el, index) => {
          let url = "/images/office/default.png";

          if (Array.isArray(el.upload_info) && el.upload_info.length > 0) {
            if (el.upload_info[0].fileType == "image") {
              url = el.upload_info[0].url;
            }
          }

          return (
            <div
              key={index}
              onDragStart={handleDragStart}
              role="presentation"
              style={{
                backgroundColor: "white",
                padding: 20,
                borderRadius: 10,
                margin: 10,
              }}
            >
              <img
                src={url}
                style={{
                  width: "100%",
                  height: 300,
                  objectFit: "cover",
                }}
              />

              <div
                style={{
                  borderTop: "solid thin grey",
                  textAlign: "justify",
                  fontWeight: 500,
                  fontSize: 14,
                  marginTop: 10,
                  minHeight: 200,
                  paddingTop: 10
                }}
              >
                <div style={{ fontWeight: 700, fontSize: 18 }}>{el.title}</div>
                <br />
                <div dangerouslySetInnerHTML={{ __html: el.description }}></div>
              </div>
            </div>
          );
        })}
      />
    </div>
  );
}
