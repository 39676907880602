import React, { Component } from "react";
import { Card } from "react-bootstrap";
import {
  Container,
  Typography,
  Grid,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";
import { PostData } from "../../api/service";
import { Link } from "react-router-dom";

let years = [];
let currentYear = new Date().getFullYear();
for (let i = 0; i < 7; i++) {
  years.push(currentYear - i);
}

export default class NotificationDescription extends Component {
  state = {
    data: [],
    isLoaded: false,
  };

  __loadFile = (year) => {
    let d = {
      dept_code: "PANDU",
      type: this.props.code,
      year: year,
    };

    PostData(`/getnoticesbytypedept`, d).then((resp) => {
      this.setState({
        data: resp,
        isLoaded: true,
      });
    });
  };

  componentDidMount() {
    this.__loadFile(this.props.year);
  }

  componentDidUpdate(prevProps, prevState) {
    console.log(prevProps);
    if (prevProps.code != this.props.code) {
      this.__loadFile(this.props.year);
    }
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  showFile = (blob, index) => {
    var blob1 = this.b64toBlob(blob.data, blob.mime, 512);
    var blobUrl = URL.createObjectURL(blob1);

    var link = document.createElement("a");
    link.href = blobUrl;
    link.download = `${blob.type}_${Math.random() * 1000}.${blob.ext}`;
    link.click();
  };

  handleClick(data) {
    PostData(`/getfiledata`, data).then((resp) => {
      //console.log(resp)
      this.showFile(resp, 1);
    });
  }

  modify_name = (name) => {
    return name.slice(0, 30) + "..";
  };
  render() {
    return (
      <div style={{ minHeight: "100vh" }}>
        <Container>
          <Typography
            variant="h5"
            style={{ margin: "1em 0", fontWeight: 700 }}
            gutterBottom
          >
            {this.props.title}
          </Typography>
          <div
            style={{
              backgroundColor: "#f9f5f9",
              display: "block",
              marginTop: "1em",
            }}
          >
            <h6>Visit Archives:</h6>
            {years.map((el) => (
              <a
                onClick={() => this.__loadFile(el)}
                //to={`/notifications/${this.props.type}/${el}`}
                style={{ color: "blue", padding: "0px 10px 0px 10px" }}
              >
                {el}
              </a>
            ))}
          </div>

          <br />
          <br />

          {this.state.isLoaded ? (
            <Grid container spacing={2}>
              {this.state.data.map((el, index) => (
                <Grid item key={index} xs={12} sm={12} lg={12} md={12}>
                  <Card>
                    {/* <ShowImage
                                            files={el.upload_info}
                                            nos="1"
                                            height="100%"
                                            width="100%"
                                        /> */}
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {el.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={{ __html: el.description }}
                      ></Typography>
                    </CardContent>

                    {Array.isArray(el.upload_info) ? (
                      <CardActions>
                        {el.upload_info.map((el1, index1) => (
                          <Button
                            size="small"
                            key={index1}
                            //onClick={this.handleClick.bind(this, el1)}
                            color="primary"
                            target="_blank"
                            href={el1.url}
                          >
                            Attachment {index1 + 1}
                          </Button>
                        ))}
                      </CardActions>
                    ) : null}
                  </Card>
                </Grid>
              ))}

              {this.state.data.length === 0 ||
              this.state.data.length == "undefined" ? (
                <Typography>No {this.props.title}</Typography>
              ) : null}
            </Grid>
          ) : (
            <Typography>Loading . . . </Typography>
          )}
        </Container>
      </div>
    );
  }
}
