import React, { Component } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Tabs } from "antd";
import NotificationDescription from "../Notifications/NotificationDescription";
class AboutDescription extends Component {
  render() {
    return (
      <div style={{ marginTop: "" }}>
        {this.props.type == "about" ? (
          <About title="About Pandu College" />
        ) : null}
        {this.props.type == "history" ? <History title="History" /> : null}
        {this.props.type == "vision" ? <Vission title="Vision" /> : null}
        {this.props.type == "code-of-conduct" ? <CodeOfConduct title="Code of Conduct" /> : null}
        
        {this.props.type == "icv" ? (
          <ICV title="Institutional Core Values" />
        ) : null}
        {this.props.type == "organogram" ? (
          <NotificationDescription
            type="streategies"
            code="IQAC_ORGANOGRAM"
            title="Organogram"
          />
        ) : null}
        {this.props.type == "certificates" ? (
          <NotificationDescription
            type="streategies"
            code="COLLEGE_CERTIFICATES"
            title="Certificates"
          />
        ) : null}
        {this.props.type == "best-practice" ? (
          <NotificationDescription
            type="streategies"
            code="IQAC_BEST_PRACTICE"
            title="Best Practice"
          />
        ) : null}
        {this.props.type == "institutional-distingtiveness" ? (
          <NotificationDescription
            type="streategies"
            code="IQAC_INSTITUTIONAL_DISTINGTIVENESS"
            title="Institutional Distingtiveness"
          />
        ) : null}
        {this.props.type == "academic-calendar" ? (
          <NotificationDescription
            type="streategies"
            code="IQAC_ACADEMIC_CALENDAR"
            title="Academic Canlendar"
          />
        ) : null}
        {this.props.type == "institutional-development-plan" ? (
          <NotificationDescription
            type="streategies"
            code="COLLEGE_IDP"
            title="Institutional Development Plan"
          />
        ) : null}

        {this.props.type == "perspective-deployment-plan" ? (
          <NotificationDescription
            type="streategies"
            code="COLLEGE_PPD"
            title="Perspective Plan & Deployment"
          />
        ) : null}

        {/* {this.props.type == 'mission' ? (<Seat title="Mission" />) : null}} */}
      </div>
    );
  }
}

export default AboutDescription;

export const About = (props) => {
  return (
    <div>
      <Typography
        variant="h5"
        style={{ margin: "1em 0", fontWeight: 700, fontFamily: "Roboto" }}
      >
        {props.title}
      </Typography>
      <hr />

      {/* <Typography
                variant="h6"
                gutterBottom
            >
                History
            </Typography> */}

      <Typography variant="body1" gutterBottom>
        Pandu College believes in imparting life-oriented and value based
        education- an education aimed at strengthening the body purifying the
        mind and sharpening the intellect. It is intended to make the learners
        aware of the need to cultivate such qualities that would enable them to
        integrate properly with their communities, the people of the nation and
        their physical and biological environments. It is so oriented as to help
        the learners to get exposed to the scientific and technological
        advancement as well as the economic scenario of the country and the
        world at large. Such an education will regulate their lives in such a
        manner as to make them emerge as true citizen of the world possessing
        the essential human qualities and looking at the broader aspects of life
        in its absolute sense. They shall be acquainted with the history and
        culture of the human civilization while remaining at the same time
        regardful of the culture and tradition of their own native land with
        which they are so inseparably linked. The bond of unity, amity and
        fraternity shall thus be strengthened. The students of Pandu College
        shall be governed by the noble ideals of love, tolerance, sympathy and
        fellow felling and thus build up their character and their destiny. We
        have to march forward slowly but boldly and with firm determination to
        reach that goal.
      </Typography>
    </div>
  );
};

export const History = (props) => {
  return (
    <div>
      <Typography
        variant="h5"
        style={{ margin: "1em 0", fontWeight: 700, fontFamily: "Roboto" }}
      >
        {props.title}
      </Typography>
      <hr />

      {/* <Typography
                variant="h6"
                gutterBottom
            >
                History
            </Typography> */}

      <Typography variant="body1" gutterBottom>
        The journey of the birth and growth of Pandu College is intrinsically
        associated with the expansion of NF Railway, India. Formation of NF
        Railway Head Quarters at Maligaon and the subsequent colonies along with
        the steady rise of inhabitants in the vast Pandu-Maligaon area naturally
        required a well functioning educational ecosystem. As western Guwahati
        didn’t have any institution of higher education at the time an attempt
        was made in 1960 to fill up this lacuna by prominent freedom fighter and
        social worker Sri Deben Sarma, the President and Secretary of
        Vidyamandir High Schoold, Sri S.K. Roy, Sri B.K. Mitra and Sri Satya
        Kinkar Sen representing the needs of the people. The ground work started
        in the middle of 1961, but concerns over space and fund loomed large.
        Fortunately at the initial stage, the N.F. Railway accorded permission
        to start classes in the vacated Netaji Vidyapeeth premises and also
        approved 99-year lease of 3.95 acres of railway land to set up the
        college. It would be worthwhile to mention that the institution was
        sustained by people’s contribution from various quarters in the early
        years. Many of the railway employees donated a portion of their salary
        every month from 1962-1966. Sri Satya Kinkar Sen sacrificed his job of
        Lecturer and HoD of English in the Handique Girls’ College to launch the
        new venture as its founder Principal. It was only befitting that
        September 5, observed all over the country as Teachers’ day, was chosen
        to be the birth day of the newly set up college. A solemn inaugural
        function was held on 05-09-1962 at the Railway Recreation Club, Pandu,
        in which Sri Radhika Ram Das, Minister of State for Revenue was the
        Chief Guest. The first admission notice to the Pre-University and Degree
        classes (night-shift) in the Arts and Commerce in the college was served
        on 30.11.1963. Within two years i.e. by 1965, the Science and Arts
        streams had become functional for both day and night shifts. Such was
        the dedication of the teachers that within a short span of time, Pandu
        College retained the distinction of being one of the most preferred
        institutions of higher education and learning. The honours courses were
        introduced phase-wise from 1967- 68 and the college, in the meantime,
        was affiliated to Gauhati University. At present, merely14.3 km away
        from state’s capital and about 16.5 km from Gopinath Bordoloi
        International Airport, the college is well connected with road
        transport, railway transport and airways. Currently college comprises 22
        full-fledged departments spread over Arts, Commerce and Science
        disciplines. Besides, providing education at undergraduate level, seven
        Post Graduate courses are also offered in different subjects. The
        Internal Quality Assurance Cell (IQAC) of the college was established in
        2003 and three consecutive cycles of NAAC visits have been completed.
        College has been accredited with CGPA 2.38 in the Third Cycle visited by
        NAAC held in 2019.
      </Typography>
    </div>
  );
};

export const Vission = (props) => {
  return (
    <div>
      <Typography
        variant="h5"
        style={{ margin: "1em 0", fontWeight: 700, fontFamily: "Roboto" }}
      >
        {props.title}
      </Typography>
      <hr />

      <Typography variant="body1" gutterBottom>
        <p>
          To transform the college to a centre of excellence by imparting
          quality and value based education for all round development of the
          students.
        </p>
      </Typography>

      <Typography
        variant="h5"
        style={{ margin: "1em 0", fontWeight: 700, fontFamily: "Roboto" }}
      >
        Mission
      </Typography>
      <hr />

      <Typography variant="body1" gutterBottom>
        <p>
          <ul>
            <li>
              To enrich and empower the students through quality education{" "}
            </li>
            <li>
              To ensure a learner centric environment equipped with the latest
              techniques and technologies{" "}
            </li>
            <li>
              To ensure the availability of efficient academic, student support
              and career guidance services
            </li>
            <li>
              To develop the skills of the students in diverse fields through
              participation in literary, cultural, sports and extension
              activities
            </li>
            <li>
              To inculcate social, moral, and spiritual integrity among students
            </li>
          </ul>
        </p>
      </Typography>

      {/* <Typography variant="body1" gutterBottom>
        <p>
          The celebration of Golden Jubilee is a memorable event for Pandu
          College. On October 23, 2011 former President of India, Hon'ble A P J
          Abdul Kalam formally inaugurated the ceremony. The closing ceremony
          will be attended by Hon'ble President of India, Pranab Mukherjee as
          the Chief Guest on May 13, 2013.
        </p>
        <p>
          Established on 5th September 1962, the college has grown from strength
          to strength during these years to cater to the educational needs of
          whole north-eastern region of India in general and greater
          Pandu-Maligaon area in particular. It started in a very humble way in
          the abandoned thatch houses of Netaji Vidyapith H. S. School of NF
          Railway in the year 1962 as a nightshift college with only humanities.
          At present, the college has a number of RCC buildings, 2 Post Graduate
          courses, 22-degree courses and 3 certificate courses. Present student
          strength is around 2500, Faculty strength is 101 with adequate number
          of office staff and grade IV employees.
        </p>
        <Typography variant="h5" gutterBottom>
          APPRAISAL OF THE COLLEGE
        </Typography>
        <p>
          In order to formulate a vision document of Pandu College for next
          fifty years, an endeavour has been made to throw some light on the
          prospects, deficiencies, problems and scope of Pandu College, which
          are outlined below:
        </p>
        <Typography variant="h6" gutterBottom>
          Prospects:
        </Typography>
        <ol>
          <li>
            Situational advantage – serenity in the midst of metropolitan
            hassles.
          </li>
          <li>
            The rapid urbanization in the villages around NF Railway HQ
            establishment will lead to a phenomenal increase of stakeholders of
            Pandu College.
          </li>
          <li>
            Students of different ethnic groups are never allowed to indulge in
            any sort of unbecoming behaviour. The administration is very stern
            in the maintenance of discipline and decorum of the institution.
          </li>
          <li>
            Introduction of different new courses and curriculum keeping in mind
            the demand of time is a special feature of the college.
          </li>
          <li>
            Faculty members are devoted and highly qualified with 36 Ph.D degree
            holders and having completed 6 major and 23 minor research projects.
          </li>
          <li>
            The college has an Internal Quality Assurance Cell, which closely
            monitors the academic advancement. The college also gives
            recognitions to the best department annually on the basis of setup
            criteria. Certification by NAAC as to the quality of education in
            the college had been accredited twice.
          </li>
          <li>
            The Information and Career Guidance Cell through its various
            activities benefits the learners. NSS and NCC wings are involved in
            leadership development and motivating the students for social work
            as evident by recognition of Programme Officer in the National
            level.
          </li>
          <li>
            Hostel facilities for boys, girls and women attract a large number
            of outstation students.
          </li>
          <li>
            A large percentage of female students (over 50%) and Women
            Empowerment Cell in the college are indicative of the process of
            women emancipation.
          </li>
        </ol>
        <Typography variant="h6" gutterBottom>
          Deficiencies:
        </Typography>
        <ol>
          <li>
            The paucity of land area of the present campus is a hindrance to the
            infrastructural extension of the college.
          </li>
          <li>Lack of community interaction of the college.</li>
          <li>Absence of benchmark to any discipline of the college.</li>
          <li>
            The concept of de-linking degrees from jobs as recommended in NPE
            1986 is yet to gain ground in the college.
          </li>
          <li>
            There is dearth of students of high calibre. On the other hand,
            student of good calibre from out station could not take admission
            for lack of hostel accommodation.
          </li>
          <li>
            Facilities for extra academic activities are not up to the mark.
          </li>
          <li>
            A major portion of the library books is old and outdated. Full
            computerisation of the library is pending.
          </li>
          <li>
            Research facilities infrastructure for all streams of the college,
            and science in particular, needs improvement.
          </li>
          <li>
            Refresher and orientation courses are essentially information
            updating with less or no importance to pedagogic aspects.
          </li>
        </ol>
        <Typography variant="h6" gutterBottom>
          Problems:
        </Typography>
        <ol>
          <li>
            The college may have to face problems in meeting the changing needs
            and aspirations of the stakeholders due to the rapid urbanization of
            immediate neighbourhood. Private institutions designed to meet such
            needs and aspirations will pose problem to the college.
          </li>
          <li>
            Paucity of fund stands on the way to the improvement of
            infrastructure facilities though the unit cost per student is at
            present Rs 2580 without salary component and expected to increase
            considerably.
          </li>
          <li>
            Less importance in creativity and innovation in teaching learning
            process
          </li>
          <li>
            Introduction of new courses without undertaking survey of the needs
            and aspiration of the stakeholders and marketability is a problem.
          </li>
        </ol>
        <Typography variant="h6" gutterBottom>
          Scope:
        </Typography>
        <ol>
          <li>
            Pandu college is capable of transforming the college into a Centre
            of Excellence with the help of committed faculty, disciplined
            students and infrastructure facilities through a long term planning.
          </li>
          <li>
            Scope for collaboration with emerging industrial establishments in
            this region for under taking Research and Development (R & D) work.
          </li>
          <li>
            Similarly attempts for such collaboration in R&D work may be
            explored from different research centres, universities, professional
            institutions and institutions involved in promotion and conduct of
            educational research.
          </li>
          <li>
            There is scope for greater involvement of alumni members for
            material help and development of the college through their
            expertise.
          </li>
          <li>
            Engagements of professionals in specific areas on contractual basis
            may help to achieve the vision.
          </li>
        </ol>
        <p>
          In view of the prospects, problems and scope, the vision statement of
          Pandu College is visualised and presented as below. Here it is to be
          pointed out that the action plan for achieving the vision will be
          worked out in due course.
        </p>
        <Typography variant="h5" gutterBottom>
          VISION STATEMENT
        </Typography>
        <p>
          Pandu College visualizes to attain the status of Centre of Excellence
          for development of human resources and nation building.
        </p>
        <Typography variant="h5" gutterBottom>
          THRUST AREAS FOR ACTION PLAN
        </Typography>
        <p>
          <strong>1. Academic Domain:</strong> This portion will cover quality
          control, introduction of new courses, admission, co-curricular and
          extra-curricular activities and health and hygiene.
        </p>
        <ul>
          <li>
            <p>
              <strong>1.1. Quality Assurance:</strong> The existing IQAC be
              activated fully to formulate methodology for regular monitoring
              the progress of each department including professional and
              vocational courses. Each department should submit monthly the
              coverage of teaching plan to the IQAC, which in turn will deposit
              its copy to the Principal/Vice Principal. A regular feedback from
              learners be obtained to adopt corrective measures, if any. The
              cell should also arrange orientation courses during vacation
              particularly on pedagogical aspects. The cell should also motivate
              departments to take field study, community interaction, visiting
              some centres (Assembly, Refinery, Industrial and Research units)
              and carry out project works by students in group, the plan of
              which is to be submitted before preparation of academic calendar.
            </p>
            <p>
              At present, a small committee may be constituted with posts like
              coordinators, Asstt. Coordinators and two members. The committee
              should work for 3 years on succession basis i.e. after 3 years
              coordinator will be relieved and Asstt. Coordinator will assume
              this position and a new member will be inducted. This system is to
              continue until a salaried professional is engaged for the purpose.
              Coordinators and Asstt. Coordinator may be sent to Educational
              Research centre or Centre of Excellence for gaining experience in
              quality control in education and know the developments and trends
              in education.
            </p>
            <p>
              There should be regular interaction between IQAC and Academic
              Committee. Both will jointly submit a factual annual report of
              academic advancement to the Principal who in turn will apprise the
              Governing Body.
            </p>
          </li>
          <li>
            <p>
              <strong>1.2. Introduction of new courses:</strong> A committee is
              to be setup before planning to introduce new courses. Members are
              expected to be well versed with the demand of different subjects,
              viability of the courses and acceptability by the students. For
              professional and vocational courses, scope of self-employment and
              employability is to be judged by field survey. Opening of
              postgraduate courses be restricted to subjects which department is
              fully equipped with research facility and proven aptitude for
              research work. Preference be also given to some non-conventional
              subjects. Thus, it is possible to develop courses for Sattriya
              Dance in degree and Post Graduate level. Certificate courses may
              be opened for proof reading and translation keeping in view of
              increasing demand of skilled persons in Press, Newspaper houses
              and Electronic media.
            </p>
            <p>
              The role of teachers in near future will be reduced to
              facilitator. The process of e- tutoring and elearning has to be
              introduced at one point for which preparation may be started.
            </p>
          </li>

          <li>
            <p>
              <strong>1.3 Admission:</strong> The computerised admission process
              be continued with change in parameters from time to time.
              Admission committee should determine the intake capacity. Some
              seats may be reserved for those students who have excelled in
              sports and performing arts and who have represented the state at
              least once. Free education may be offered to some meritorious but
              economically backward students.
            </p>
            <p>
              The college should also arrange to interact with feeder schools to
              project about the courses offered, facilities and achievements.
              Some conscious public relation work be done for image building of
              the college.
            </p>
          </li>

          <li>
            <p>
              <strong>
                1.4. Co-curricular and Extra-curricular activities:
              </strong>{" "}
              Each department should organise departmental seminars by students
              on a regular basis as a part of teaching-learning process. Debates
              and quizzes may also be encouraged.
            </p>
            <p>
              NCC and NSS wings are active but needs guidance for annual
              planning in the pursuit of leadership development and community
              service. The college should see that plans are not disrupted due
              to fund crunch.
            </p>
          </li>

          <li>
            <p>
              <strong>1.5. Health and Hygiene:</strong> Gymnasium hall of the
              college be made fully operational with increasing facilities. Yoga
              and meditation classes be held at regular intervals. Facilities be
              provided for indoor games and arrangement of coaching of talented
              students.
            </p>
            <p>
              The college should conduct Health Awareness and Check up camps
              with the help of Alumni Association at least twice a year.
              Students be encouraged for blood donation and a blood donors
              register may be maintained by NSS to serve the community.
            </p>
            <p>
              Setting up of a hospital in proposed second campus is also
              suggested.
            </p>
          </li>
        </ul>
        <p>
          <strong>2 Research and Extension:</strong> Research is a vital
          component of higher education. Promotion of faculty is now linked with
          research activity. The college so far has no control for initiation of
          research activity and monitoring the progress. Thus, an expert
          committee is to be constituted to motivate researchers to take up
          projects of regional and national importance. The following are some
          observations:
        </p>
        <ul>
          <li>
            <p>
              <strong>2.1. Research Facilities:</strong>The College should
              gradually increase the research infrastructural facilities on
              priority basis.
            </p>
          </li>
          <li>
            <p>
              <strong>2.2 Collaborative Research:</strong> Collaborative
              research projects may be taken up through MoU with Universities,
              Industries and Research Institutions. Inter departmental
              collaborative research activities should also be promoted.
            </p>
          </li>

          <li>
            <p>
              <strong>2.3. Educational Research:</strong> Faculty members may be
              encouraged to take up projects related to quality control in
              academics of the college.
            </p>
          </li>

          <li>
            <p>
              <strong>2.4. Consultancy Service:</strong> Some departments or
              individual members may utilise their skill for consultancy
              service. Faculty members may go for specialised training for the
              purpose.
            </p>
          </li>

          <li>
            <p>
              <strong>2.5. Archive:</strong> An archive be created in future to
              preserve the manuscripts, folk songs and musical instruments of
              the region and details of which can be worked out with the experts
              in the field. This will act as a resource centre to carry out
              research work in this field.
            </p>
          </li>

          <li>
            <p>
              <strong>2.6. Uploading research findings:</strong> Projects and
              research work carried out by the faculties should be placed in the
              college website. A price tag may be attached for those who want to
              utilise the research findings
            </p>
          </li>
        </ul>
        <p>
          <strong>3. Infrastructure:</strong> Existing campus area of the
          college is small and there is no scope of further extension work
          except vertical extension. The following proposals are suggested.
        </p>
        <ul>
          <li>
            <p>
              <strong>3.1 Renovation:</strong> The existing Assam type
              accommodation may be replaced by earthquake resistant
              multi-storied buildings.
            </p>
          </li>
          <li>
            <p>
              <strong>3.2 Laboratory accommodation:</strong> Provision for
              adequate space for research work especially for science stream is
              to be worked out.
            </p>
          </li>
          <li>
            <p>
              <strong>3.3 Modernisation:</strong> Modernisation of laboratories
              is required for learners and researchers. 3.4 Indoor stadium: Laid
              down foundation of indoor stadium cum auditorium be completed
              within a time frame.
            </p>
          </li>
          <li>
            <p>
              <strong>3.5 Staff quarter:</strong> The land existing behind
              science block may be utilised for construction of quarters of
              grade IV staff.
            </p>
          </li>
          <li>
            <p>
              <strong>3.6 Second campus:</strong> In view of the paucity of land
              in the existing campus, a second campus within a radius of ten to
              fifteen kilometres is proposed. The area of which should be at
              least ten acres. Following facilities may be incorporated in the
              second campus in phased manner.
            </p>
            <ol>
              <li>Construction of an outdoor stadium</li>
              <li>
                To create an archive for manuscripts and folk songs of the
                region
              </li>
              <li>Construction of a sports complex with swimming pool</li>
              <li>Residential quarters for faculty members</li>
              <li>A recreation centre</li>
              <li>Botanical garden and animal house for research activities</li>
              <li>A small hospital cum dispensary</li>
              <li>Shade for parking of vehicles</li>
              <li>A cooperative store</li>
            </ol>
          </li>
        </ul>
        <p>
          <strong>4. Source of finance:</strong> In all the above-mentioned
          developmental works, a lot of fund will be necessary. There should be
          a concerted effort for generation of financial resources by various
          means. Consultancy service of a financial advisor or a firm may be
          needed for the purpose. The following are some suggestions in this
          regard.
        </p>
        <ul>
          <li><p><strong>4.1 Grant management:</strong> Financial assistance and grants
        available from government, UGC and other funding agencies are to be
        fully explored and proper action plan be made.</p></li>
          <li><p><strong>4.2 Sponsorship:</strong> When land property is made available to the college, some industrial houses         and corporate sectors may be approached for sponsoring big projects like stadium, archive, swimming pool, etc. Experts in these fields may be consulted for the purpose.</p></li>
          <li><p><strong>4.3 Alumni:</strong> Established alumni may offer help substantially to the institution. Efforts should be on for finding such resourceful alumni.</p></li>
          <li>
            <p><strong>4.4 In-house fund generation:</strong> The College should think of generation of funds from various resources, some of which are mentioned below:</p>
            <ol>
              <li>Renting the auditorium or class rooms</li>
              <li>From consultancy services</li>
              <li>From research projects</li>
              <li>From sale of products made by students of vocational courses, etc</li>
            </ol>
          </li>
        </ul>
        <p>Thrust areas of vision document are to be reviewed and modified after every three to five years for making it relevant. Action plans are to be prepared on the basis of immediate, intermediate and ultimate.</p>
      </Typography> */}
    </div>
  );
};

const { TabPane } = Tabs;

export const ICV = (props) => {
  return (
    <div>
      <Typography
        variant="h5"
        style={{ margin: "1em 0", fontWeight: 700, fontFamily: "Roboto" }}
      >
        {props.title}
      </Typography>
      <hr />

      <div style={{ textAlign: "center" }}>
        <img src={`/images/THEME_SONG.jpg`} style={{ width: "600px" }} />

        <br />
        <br />

        <img src={`/images/CORE_VALUES.jpg`} style={{ width: "500px" }} />
      </div>
    </div>
  );
};

export const CodeOfConduct = (props) => {
  return (
    <div>
      <Typography
        variant="h5"
        style={{ margin: "1em 0", fontWeight: 700, fontFamily: "Roboto" }}
      >
        {props.title}
      </Typography>
      <hr />

      <div style={{ textAlign: "center" }}>
        <iframe src={`/pdf/code_of_conduct_pc.pdf`} style={{width: "100%", height: "100vh"}}></iframe>
      </div>
    </div>
  );
};
