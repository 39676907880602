import React, { Component } from "react";
import { Divider, Grid, Typography } from "@material-ui/core";
import CommiteeCode from "../Committee/CommiteeCode";
import IqacMember from "./IqacMemeber";
import { GetData, PostData } from "../../api/service";
import NotificationDescription from "../Notifications/NotificationDescription";
import IqacMatrics from "./IqacMatrics";
import IqacSsrMatrics from "./IqacSsrMatrics";

const initialState = [];
class IqacDescription extends Component {
  render() {
    return (
      <div>
        {this.props.type == "matrix" ? <Building2 type="strategies" code="IQAC_STRATEGIES" title="IQAC Strategies" /> : null}
        {this.props.type == "ssrmatrix" ? <Building21 type="strategies" code="IQAC_STRATEGIES" title="IQAC Strategies" /> : null}
        {this.props.type == "strategies" ? <NotificationDescription type="strategies" code="IQAC_STRATEGIES" title="IQAC Strategies" /> : null}
        {this.props.type == "functions" ? <NotificationDescription type="streategies" code="IQAC_FUNCTIONS" title="IQAC Functions" /> : null}
        {this.props.type == "teams" ? <CommiteeCode committee_code="IQAC" /> : null}
        {this.props.type == "ssr" ? <NotificationDescription type="streategies" code="IQAC_SSR" title="IQAC Self Study Reports" /> : null}
        {this.props.type == "aqar" ? <NotificationDescription type="streategies" code="IQAC_AQAR" title="IQAC AQAR" /> : null}
        {this.props.type == "atr" ? <NotificationDescription type="streategies" code="IQAC_ATR" title="IQAC Action taken Report & Meeting Minutes" /> : null}
        {this.props.type == "minutes" ? <NotificationDescription type="streategies" code="IQAC_MIUNTES" title="IQAC Minutes" /> : null}
        {this.props.type == "pos" ? <NotificationDescription type="streategies" code="IQAC_POS" title="Program Outcomes" /> : null}
        {this.props.type == "feedback" ? <NotificationDescription type="streategies" code="IQAC_FEEDBACK_FORM" title="IQAC Feedback Form" /> : null}
        {this.props.type == "feedback-analysis" ? <NotificationDescription type="streategies" code="IQAC_FEEDBACK_ANALYSIS" title="IQAC Feedback Analysis" /> : null}
        {this.props.type == "sss" ? <NotificationDescription type="streategies" code="IQAC_SSS" title="IQAC Student Satisfactory Survey" /> : null}
        {this.props.type == "event-news" ? <NotificationDescription type="streategies" code="IQAC_EVENT" title="IQAC News/Event" /> : null}
        {this.props.type == "co_po" ? <CoPoDepartment  /> : null}
        {this.props.type == "best-practice" ? <NotificationDescription type="streategies" code="IQAC_BEST_PRACTICE" title="Best Practice" /> : null}
        {this.props.type == "institutional-distingtiveness" ? <NotificationDescription type="streategies" code="IQAC_INSTITUTIONAL_DISTINGTIVENESS" title="Institutional Distingtiveness" /> : null}
        {this.props.type == "annual-report" ? <NotificationDescription type="streategies" code="IQAC_ANNUAL_REPORT" title="Annual Report" /> : null}
        {this.props.type == "academic-calendar" ? <NotificationDescription type="streategies" code="IQAC_ACADEMIC_CALENDAR" title="Academic Canlendar" /> : null}
        {this.props.type == "organogram" ? <NotificationDescription type="streategies" code="IQAC_ORGANOGRAM" title="Organogram" /> : null}
        {this.props.type == "iqac-newsletter" ? <NotificationDescription type="streategies" code="IQAC_NEWSLETTER" title="IQAC Newsletter" /> : null}
        {this.props.type == "mous-collaborations" ? <NotificationDescription type="streategies" code="IQAC_MOUS" title="MOUs & Collaborations" /> : null}
      </div>
    );
  }
}

export default IqacDescription;

export const Building1 = (props) => {
  return (
    <div className="mb-4">
      <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
        IQAC Members
      </Typography>
      <IqacMember data={initialState} />
    </div>
  );
};


export const Building2 = (props) => {
  return (
    <div className="mb-4">
      <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
        AQAR Matrices
      </Typography>
      <IqacMatrics />
    </div>
  );
};


export const Building21 = (props) => {
  return (
    <div className="mb-4">
      <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
        SSR Matrices
      </Typography>
      <IqacSsrMatrics />
    </div>
  );
};


export const CoPoDepartment = (props) => {

  const [data, setData] = React.useState([])
  const [isLoaded, setIsLoaded] = React.useState(false)

  React.useEffect(() => {
    GetData(`/getdepartments`).then((resp) => {
    setData(resp)
    setIsLoaded(true)
      
    })
  }, [])
  return (
      <div className='mb-4'>
          <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
              All COs & POs
          </Typography>


          {isLoaded && <div>
            
            <ul>
              {Array.isArray(data) && data.map((el,index) => <li key={index}>
                  <a style={{fontSize: 14}} targte="_blank" href={`/department/${el.dept_code}/co_po`}>COs & POs list of {el.dept_name}</a>
                </li>)}
              </ul>
            </div>}

          
      </div>
  )
}
