import React, { Component } from "react";
import { Container, MDBRow, MDBCol } from "mdbreact";
import BreadCumb from "./../component/Department/BreadCumb";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";

export default class ContactUsContainer extends Component {
  render() {
    return (
      <div>
        {/* <BreadCumb
          title={`Contact us`}
          data={[
            {
              title: "Home",
              path: "/",
              isActive: false,
            },
            {
              title: "Contact Us",
              path: "/contactus",
              isActive: true,
            },
          ]}
        />
        baad me sochega
         */}
         <BreadCrumbOwn title="Contact us" />
        <Container style={{minHeight: '600px'}}>
          <br></br>
          <div>
            <MDBRow>
              <MDBCol lg="4" sm="12" md="4" xs="12" className="p-4">
                <ul>
                  <li style={{ paddingBottom: "",listStyle:'none',fontSize:'1.2em', marginTop:'3em'}}>
                    <h6>Postal Address</h6>
                    Pandu College
                    <br/>
                    Guwahati -781 012 , Assam, India.
                    <br/>
                    Pin 781012
                    <br />
                    Email:{" "}
                    <a href={`mailto:info@panducollege.org`}>
                    info@panducollege.org
                    </a>
                    <br />
                    Webmail:{" "}
                    <a href={`mailto:info@panducollege.org`}>
                    info@panducollege.org
                    </a>
                    <br />
                    Website:{" "}
                    <a href={`http://panducollege.org/`}>
                    http://panducollege.org/
                    </a>{" "}
                    <br />
                    Phone: <a href={`tel:03612570450`}>0361 - 2570450</a> , <a href={`tel:03612673698`}>0361 - 2673698</a>
                    <br />
                    Fax: <a href={`tel:03612570450`}>0361 - 2570450</a> , <a href={`tel:03612673698`}> 0361 - 2673698</a>
                  </li>
                </ul>
              </MDBCol>
              <MDBCol
                lg="8"
                sm="12"
                md="8"
                xs="12"
                className="p-3"
                style={{ textAlign: "center" }}
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3580.9591574977094!2d91.68989191502926!3d26.165459583455096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a5b1c917bdba1%3A0xb5ba898835797d7f!2sPandu%20College!5e0!3m2!1sen!2sin!4v1641802117421!5m2!1sen!2sin"
                  width="600"
                  height="400"
                  style={{border:0,maxWidth:'100%'}}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </MDBCol>
            </MDBRow>
          </div>
        </Container>
      </div>
    );
  }
}
