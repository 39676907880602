import React, { Component } from "react";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  Button,
} from "mdbreact";
import { Card, CardHeader } from "reactstrap";
import BreadCumb from "../Department/BreadCumb";
import { PostData } from "../../api/service";


export default class VicePrincipalContainer extends Component {
  render() {
    return (
      <div>
        <div style={{ paddingTop: "20px" }}>
          <br />

          <div>
           

            <div>
              <MDBRow className="mt-2">
                <MDBCol lg="12" sm="12" xs="12" md="12">
                  <div className="new-principal-div">
                    <h5
                      style={{
                        textAlign: "center",
                        color: "#1E3760",
                        fontSize: "1.5em",
                        marginTop: "10px",
                        fontWeight: 700,
                      }}
                    >
                      From the Desk of Vice Principal
                    </h5>
                    <p
                      style={{
                        textAlign: "justify",
                        marginTop: "3vh",
                        fontWeight: 500,
                      }}
                    >
                      <i>
                      A warm welcome is being extended to every student seeking admission in Pandu College – an institution with a glorious legacy. This premier institution has always placed the students in the forefront of its focus.  The talented Pandu College faculty is committed to deliver the best education and to nurture the students to reach their highest potential. The endeavor of the Pandu College team (including the non-teaching staff) is to adequately equip the students to reach their career goals and to face life’s challenges with requisite skill and confidence.    
                      </i>
                    </p>

                    <br />

                    <div style={{ textAlign: "left" }}>
                      <img
                        alt=""
                        src={"/images/pc-vice-p.jpeg"}
                        style={{
                          width: "200px",
                          boxShadow: "1px 2px 10px rgba(0,0,0,0.1)",
                          border: `solid 5px wheat`,
                        }}
                      />
                    </div>
                    <br />
                    <h5 style={{ fontSize: "16px", fontWeight: 700 }}>
                      Dr. Chandana Sinha Roy
                    </h5>
                    <h6 style={{ fontSize: "12px" }}>
                      Vice Principal
                      <br />
                      Pandu College
                    </h6>
                  </div>
                </MDBCol>
              </MDBRow>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
