import React, { Component } from 'react'
import { Container, Badge } from 'mdbreact';
import { PostData } from '../api/service';
import { Link } from 'react-router-dom'
import { RightCircleOutlined } from '@ant-design/icons';

import Marquee from "react-fast-marquee";

const style = {
  display: 'flex',
  flexDirection: 'row'
}

class FleshNews extends Component {
  state = {
    news: [],
    isLoaded: false
  }

  componentDidMount() {
    // perform the api call

    let d = {
      type: 'COLLEGE_ACADEMIC_NOTICE',
      dept_code: "PANDU"
    }

    PostData(`/getnoticesbytypedeptv1`, d)
      .then((resp) => {

        //console.log("🚀 ~ file: Fleshnew.js ~ line 27 ~ FleshNews ~ .then ~ resp", resp)

        this.setState({
          news: resp,
          isLoaded: true
        })

      })


  }
  render() {
    return (
      <div style={{ marginTop: 0}}>



        {this.state.isLoaded ? (<Marquee style={{width: '90vw'}} pauseOnHover={true} speed={25} gradient={false}>
        


            {this.state.news.map((el, index) =>
              <Link to="/notifications/academic">
                <div style={style}>
                  <div style={{ paddingRight: '20px', fontSize: 14, color: "black", fontWeight: 600, display: 'flex', justifyContent: "center", alignItems: "center" }}>
                    <RightCircleOutlined /> &nbsp; {el.title}
                  </div>
                </div>
              </Link>
            )}

         
        </Marquee>) : null}

      </div>
    );
  }
}
export default FleshNews;