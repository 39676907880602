import React, { Component } from "react";
import { GetData } from "../../api/service";
import { Typography } from "@material-ui/core";

import { Menu } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";
const { SubMenu } = Menu;

const data = [
  {
    title: "HOME",
    path: "/",
    hasSubnav: false,
    subnav: [],
  },
  {
    title: "ABOUT US",
    path: "/aboutus/about",
    hasSubnav: false,
    subnav: [],
  },
  {
    title: "ADMINISTRATIONS",
    path: "/administrations/principal",
    hasSubnav: false,
    subnav: [],
  },
  {
    title: "EVENTS",
    path: "/events",
    hasSubnav: false,
    subnav: [],
  },
  {
    title: "FACILITIES",
    path: "/facility/library",
    hasSubnav: false,
    subnav: [],
  },
  {
    title: "E-CONTENTS",
    path: "/e-content/class_note",
    hasSubnav: false,
    subnav: [],
  },
  {
    title: "STUDENTS CORNER",
    path: "/students/notification",
    hasSubnav: false,
    subnav: [],
  },
  {
    title: "RESEARCH",
    path: "/research/PANDU",
    hasSubnav: false,
    subnav: [],
  },

  {
    title: "NOTICES",
    path: "/notifications/administrative",
    hasSubnav: false,
    subnav: [],
  },

  {
    title: "DEPARTMENTS",
    path: "/departments",
    hasSubnav: false,
    subnav: [],
  },

  {
    title: "IQAC",
    path: "/iqac/streategies",
    hasSubnav: false,
    subnav: [],
  },
  {
    title: "FEEDBACKS",
    path: "/iqac/streategies",
    hasSubnav: true,
    subnav: [
      {
        title: "Alumni Feedback Form" ,
        path: "#1",
        link: "https://forms.gle/ef4cMjpdshSxmgQr6",
        isLink: true,
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Teachers’ Feedback Form",
        path: "#2",
        link: "https://forms.gle/wizEBNcNeXE3rd5c7",
        isLink: true,
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Employers Feedback Form",
        path: "#3",
        link: "https://forms.gle/fnfRagU5TBPrk3nF8",
        isLink: true,
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Students’ Feedback Form",
        path: "#4",
        link: "https://forms.gle/zYJ7sKEoQf7RrZhz8",
        isLink: true,
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Parents’ Feedback Form",
        path: "#5",
        link: "https://forms.gle/p5a89dZVS6zoCcVh8",
        isLink: true,
        hasSubnav: false,
        subnav: [],
      },
      // {
      //   title: "Students’ Grievances Form",
      //   path: "#6",
      //   link: "https://forms.gle/odRQ86uxKVgdGBRk9",
      //   isLink: true,
      //   hasSubnav: false,
      //   subnav: [],
      // },
      // {
      //   title: "Employee’s Grievance Form",
      //   path: "#7",
      //   link: "https://forms.gle/Eorm4pWAvv37N29M6",
      //   isLink: true,
      //   hasSubnav: false,
      //   subnav: [],
      // },
      // {
      //   title: "Students’ Grievances, Feedback and Suggestion Form",
      //   path: "#8",
      //   link: "https://forms.gle/CFUsAP6nyig9QCii6",
      //   isLink: true,
      //   hasSubnav: false,
      //   subnav: [],
      // },
      {
        title: "Feedback Analysis & Action Taken Report",
        path: "/feedback/feedback-analysis",
        hasSubnav: false,
        subnav: [],
      },
    ],
  },

  // {
  //   title: 'NIRF',
  //   path: '/nirf',
  //   hasSubnav: false,
  //   subnav: []
  // },

  // {
  //   title: 'RTI',
  //   path: '/rti/home',
  //   hasSubnav: false,
  //   subnav: []
  // },
  // {
  //   title: 'ALUMNI',
  //   path: '/alumni/committee',
  //   hasSubnav: false,
  //   subnav: []
  // },
  // {
  //   title: 'CONTACT US',
  //   path: '/contactus',
  //   hasSubnav: false,
  //   subnav: []
  // }
];

const pos = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  zIndex: 1000,
  justifyContent: "end",
};

class MultiNavBar extends Component {
  state = {
    departments: [],
    isLoaded: false,
    data: data,
    current: "/",
  };

  componentDidMount() {
    GetData(`/getdepartments`).then((resp) => {
      //console.log(resp)

      // arrange them

      let arts = resp.filter((el) => el.stream == "ARTS");
      let science = resp.filter((el) => el.stream == "SCIENCE");

      let arts_arr = [];
      arts.map((el, index) =>
        arts_arr.push({
          title: el.dept_name,
          path: `/department/${el.dept_code}`,
          hasSubnav: false,
          subnav: [],
        })
      );

      let science_arr = [];
      science.map((el, index) =>
        science_arr.push({
          title: el.dept_name,
          path: `/department/${el.dept_code}`,
          hasSubnav: false,
          subnav: [],
        })
      );

      let arts_d = {
        title: "Arts Departments",
        path: "",
        hasSubnav: true,
        subnav: arts_arr,
      };

      let science_d = {
        title: "Science Departments",
        path: "",
        hasSubnav: true,
        subnav: science_arr,
      };

      let d = [arts_d, science_d];

      let data = this.state.data;
      data[4].subnav = d;

      // for people

      let teaching_people = resp.map((el, index) => {
        return {
          title: el.dept_name,
          path: `/department/${el.dept_code}/faculty`,
          hasSubnav: false,
          subnav: [],
        };
      });

      //data[5]['subnav'][1]['subnav'] = teaching_people

      //console.log(data)
      this.setState(
        {
          data: data,
          isLoaded: true,
          current: this.props.history.location.pathname,
        },
        () => {
          // console.log(this.state)
        }
      );
    });
  }

  handleClick = (e) => {
    if (e.key != "NONE") {
      this.props.history.push(e.key);
      this.setState({
        current: e.key,
      });
    }
  };

  render() {
    return (
      <div
        style={
          this.props.sticky
            ? {
                ...pos,
              }
            : null
        }
        className={`navStyle-new`}
      >
        <Menu
          onClick={this.handleClick}
          style={{
            background: "#404040",
            border: "none",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
          selectedKeys={[this.state.current]}
          overflowedIndicator={<MenuUnfoldOutlined />}
          mode="horizontal"
        >
          {this.state.data.map((el, index) => {
            // Pandu college or kunu sublink nathake
            if (el.hasSubnav) {
              return (
                <SubMenu title={el.title} className="navItemPandu">
                  {el.subnav.map((el1, index1) => {
                    if (el1.hasSubnav) {
                      return (
                        <SubMenu title={el1.title}>
                          {el1.subnav.map((el2, index2) => (
                            <Menu.Item key={el2.path}>{el2.title}</Menu.Item>
                          ))}
                        </SubMenu>
                      );
                    } else {
                      if (el1.isLink) {
                        return (
                          <Menu.Item key="NONE">
                            <a href={el1.link}>{el1.title}</a>
                          </Menu.Item>
                        );
                      } else {
                        return (
                          <Menu.Item key={el1.path}>{el1.title}</Menu.Item>
                        );
                      }
                    }
                  })}
                </SubMenu>
              );
            } else {
              return (
                <Menu.Item key="mail" key={el.path} className="navItemPandu">
                  {el.title}
                </Menu.Item>
              );
            }
          })}
        </Menu>

        {/* <div
          style={{
            display: 'flex',
            backgroundColor: this.props.backgroundOfNav,
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '1px 2px 3px rgba(0,0,0,0.4)',
            width: '100%',
            //overflowY: 'auto'
          }}
        >
          {this.state.data.map((el, index) =>
            <OneNavBar
              key={index}
              {...el}
              width={this.props.width}
              color={this.props.color}
              backgroundOfNav={this.props.backgroundOfNav}
              backgroundColor={this.props.backgroundColor}
              textColor={this.props.textColor}
              history={this.props.history}
            />
          )}
        </div> */}
      </div>
    );
  }
}

export default MultiNavBar;

class OneNavBar extends Component {
  state = {
    hover: false,
  };

  onMouseEnter = () => {
    this.setState({
      hover: true,
    });
  };

  onMouseLeave = () => {
    this.setState({
      hover: false,
    });
  };

  render() {
    return (
      <div
        style={{
          padding: "10px 10px 10px 10px",
          cursor: "pointer",
          position: "relative",
          color: this.props.color,
        }}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onMouseOver={this.onMouseEnter}
        onClick={() =>
          !this.props.hasSubnav
            ? this.props.history.push(this.props.path)
            : null
        }
      >
        <Typography variant="subtitle2">{this.props.title}</Typography>

        {this.props.hasSubnav && this.state.hover ? (
          <div
            style={{
              backgroundColor: this.props.backgroundColor,
              position: "absolute",
              minWidth: this.props.width,
              zIndex: 999,
              display: "grid",
              gridTemplateRows: "1fr",
              boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
            }}
          >
            {this.props.subnav.map((el, index) => (
              <OneSideNav
                key={index}
                {...el}
                width={this.props.width}
                color={this.props.color}
                backgroundOfNav={this.props.backgroundOfNav}
                backgroundColor={this.props.backgroundColor}
                textColor={this.props.textColor}
                history={this.props.history}
              />
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}

class OneSideNav extends Component {
  state = {
    hover: false,
  };
  onMouseEnter = () => {
    this.setState({
      hover: true,
    });
  };

  onMouseLeave = () => {
    this.setState({
      hover: false,
    });
  };
  render() {
    return (
      <div
        style={{
          color: this.props.textColor,
          backgroundColor: this.props.backgroundColor,
          position: "relative",
          minWidth: this.props.width,
          padding: "10px",
          borderBottom: "solid thin lightgrey",
          borderRight: "solid thin lightgrey",
          boxShadow: "1px 2px 3px rgba(0,0,0,0.3)",
        }}
        onClick={() =>
          !this.props.hasSubnav
            ? this.props.history.push(this.props.path)
            : null
        }
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onMouseOver={this.onMouseEnter}
      >
        <font style={{ fontSize: "0.8em" }}>{this.props.title}</font>{" "}
        {this.props.hasSubnav ? (
          <span
            className="fa fa-angle-right"
            style={{
              fontSize: "0.6em",
              float: "right",
              marginTop: "8px",
            }}
          ></span>
        ) : null}
        {this.props.hasSubnav && this.state.hover ? (
          <div
            style={{
              position: "absolute",
              zIndex: 999,
              left: 0,
              marginLeft: this.props.width,
              top: 0,
              backgroundColor: this.props.backgroundColor,
            }}
          >
            {this.props.subnav.map((el, index) => (
              <OneSideNav
                key={index}
                {...el}
                width={this.props.width}
                color={this.props.color}
                backgroundOfNav={this.props.backgroundOfNav}
                backgroundColor={this.props.backgroundColor}
                history={this.props.history}
              />
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}
