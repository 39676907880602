import React, { Component } from "react";
import { Container, MDBRow, MDBCol } from "mdbreact";
import BreadCumb from "../component/Department/BreadCumb";
import NotificationDescription from "../component/Notifications/NotificationDescription";
import { PostData } from "../api/service";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

export default class GrievancesContainer extends Component {


  render() {
    return (
      <div>
        <BreadCrumbOwn title="Grievances" />
        <Container>
          <div className="row">
            <div className="col-md-12 pl-2 py-2" style={{ minHeight: "100vh" }}>
              <br />
              <br />

              <Paper>
                  <Table size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: 700 }}>Type</TableCell>
                        <TableCell style={{ fontWeight: 700 }}>Link</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Students’ Grievances Form</TableCell>
                        <TableCell><a target="_blank" href={`https://forms.gle/odRQ86uxKVgdGBRk9`}>Click here</a></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Employee’s Grievance Form</TableCell>
                        <TableCell><a target="_blank" href={`https://forms.gle/Eorm4pWAvv37N29M6`}>Click here</a></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
