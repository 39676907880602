import React, { Component } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import { Link } from 'react-router-dom'
import { LinkOutlined } from "@ant-design/icons";
import { Grid } from '@material-ui/core'
// import FleshNews from './Fleshnew';

const items = [
  {
    id: 1,
    src: "/gallery/images/pc-1.jpg",
    altText: "Slide 1",
    head: "Slide 1",
  },
  {
    id: 2,
    src: "/gallery/images/pc-2.jpg",
    altText: "Slide 2",
    head: "Slide 2",
  },
  {
    id: 3,
    src: "/gallery/images/pc-3.jpg",
    altText: "Slide 2",
    head: "Slide 2",
  },
  {
    id: 4,
    src: "/gallery/images/pc-4.jpg",
    altText: "Slide 2",
    head: "Slide 2",
  },
  {
    id: 5,
    src: "/gallery/images/pc-5.jpg",
    altText: "Slide 2",
    head: "Slide 2",
  },
  {
    id: 6,
    src: "/gallery/images/pc-6.jpg",
    altText: "Slide 2",
    head: "Slide 2",
  },
  {
    id: 7,
    src: "/gallery/images/pc-7.jpg",
    altText: "Slide 2",
    head: "Slide 2",
  },
];

class Example extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    const slides = items.map((item) => {
      return (
        <CarouselItem
          className="custom-tag"
          tag="div"
          key={item.id}
          onExiting={this.onExiting}
          onExited={this.onExited}
        >
          <img
            alt={item.altText}
            src={item.src}
            style={{ width: "100%", height: "500px", objectFit: "cover", objectPosition: "center center" }}
          />
          {/* <CarouselCaption
            className="text-danger"
            captionText={item.head}
            captionHeader={item.head}
          /> */}
        </CarouselItem>
      );
    });

    return (
      <div style={{ width: "100%" }}>
       
      <video poster="/video/img-fallback.png" autoPlay loop muted style={{width: "100%", height: 600, objectFit: "cover"}}>
      <source src={`/video/bg-pandu-12.webm`} type="video/webm"/>
      
      </video>
        {/* <Grid container>
         

          <Grid item xs={12} md={12} lg={12} sm={12}>

            <style>
              {`.custom-tag {
                width: 100%;
                max-height: 600px;
                background: black;
              }
              @media only screen and (max-width: 600px){
                .custom-tag {
                  height: 150px;
                }
              }
              `}
            </style>
            <Carousel
              activeIndex={activeIndex}
              next={this.next}
              previous={this.previous}
              
            >
              
              {slides}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={this.previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={this.next}
              />
            </Carousel>
          </Grid>
         

        </Grid> */}
      </div>
    );
  }
}

export default Example;
