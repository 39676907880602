import React, { Component } from "react";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Card } from "react-bootstrap";
import { GetData } from "../../api/service";
import ResearchDescription from "./ResearchDescription";
import { RightCircleOutlined } from "@ant-design/icons";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";

const list = [
  {
    title: "Library",
    link: "/facility/library",
  },
  {
    title: "Boys Hostel",
    link: "/facility/boyshostel",
  },
  {
    title: "Girls Hostel",
    link: "/facility/girlshostel",
  },
  {
    title: "CIF",
    link: "/facility/cif",
  },
  {
    title: "Canteen",
    link: "/facility/canteen",
  },
  {
    title: "ICGC",
    link: "/facility/icgc",
  },
];

class ResearchContainer extends Component {
  state = {
    link: "",
    name: "",
    data: [],
    isLoaded: false
  };
  componentDidMount() {
    this.fetchDepartments()
    let link = this.props.match.params.link;
    this.setState({
      link: link,
    });

    
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.link != this.props.match.params.link) {
      let link = this.props.match.params.link;
      this.setState({
        link: link,
      });
    }
  }



  fetchDepartments = () => {
    GetData(`/getdepartments`).then((resp) => {

      this.setState({
        data: [{
          dept_name: "College Research",
          dept_code: "PANDU"
        }, ...resp],
        isLoaded: true
      }, () => {
        if(Array.isArray(this.state.data) && this.state.data.length > 0){
          let link = this.props.match.params.link;
          if(!link){
            this.setState({
              link: this.state.data[0].dept_code
            })
          }
          
        }
      })
        
      })
   
  }

  getLastLink = (link) => {
    let ll = link.split('/');
    if(ll.length > 0){
      return ll[ll.length - 1]
    }else{
      return '';
    }
  }

  render() {
    return (
      <div>
       <BreadCrumbOwn title="Research" />
        <Container className="mb-4" style={{minHeight: '500px'}}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ paddingTop: "5em" }}
            >
              <Card
                style={{
                  boxShadow: "none",
                  position: "stickey !important",
                  top: "0 !important",
                  backgroundColor: "lightgrey"
                }}
              >
                <div
                  style={{
                    boxShadow: "none",
                    position: "stickey",
                    top: "0",
                  }}
                >
                  <List component="nav" >
                    {Array.isArray(this.state.data) && this.state.data.map((el, index) => (
                      <ListItem
                        button
                        style={{
                          //border: "1px solid #0002",
                          marginBottom: "2px",
                          backgroundColor: el.dept_code == this.state.link ? '#EBDB86' : ''
                        }}
                        
                        key={index}
                        onClick={() => this.props.history.push(`/research/${el.dept_code}`)}
                      >
                        <div
                           style={{
                            fontSize: "1.1em",
                            fontWeight: "700",
                            padding: "0",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#404040"
                          }}
                        >
                         <RightCircleOutlined />
                        &nbsp;&nbsp;
                          {el.dept_name}
                        </div>
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={9} lg={9}>

                        

              <ResearchDescription dept={this.state.data} type={this.state.link} />
            </Grid>
            
          </Grid>
        </Container>
      </div>
    );
  }
}

export default ResearchContainer;
