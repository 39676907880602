import React, { Component } from "react";
import { Container, MDBRow, MDBCol } from "mdbreact";
import BreadCumb from "./../component/Department/BreadCumb";
import NotificationDescription from "../component/Notifications/NotificationDescription";
import { PostData } from "../api/service";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

export default class NIRF extends Component {
  state = {
    data: [],
    isLoaded: false,
  };

  __loadFile = () => {
    let d = {
      dept_code: "PANDU",
      type: "NIRF",
    };

    PostData(`/getnoticesbytypedept`, d).then((resp) => {
      this.setState({
        data: resp,
        isLoaded: true,
      });
    });
  };

  componentDidMount() {
    this.__loadFile();
  }

  render() {
    return (
      <div>
        <BreadCrumbOwn title="NIRF Data" />
        <Container>
          <div className="row">
            <div className="col-md-12 pl-2 py-2" style={{ minHeight: "100vh" }}>
              <br />
              <br />

              {this.state.isLoaded && this.state.data.length > 0 && (
                <Paper>
                  <Table size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: 700 }}>Title</TableCell>
                        <TableCell style={{ fontWeight: 700 }}>File</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(this.state.data) &&
                        this.state.data.map((el, index) => (
                          <TableRow key={index}>
                            <TableCell>{el.title}</TableCell>
                            <TableCell>
                              {Array.isArray(el.upload_info) &&
                                el.upload_info.length > 0 && (
                                  <a
                                    target="_blank"
                                    href={el.upload_info[0].url}
                                  >
                                    Click Here
                                  </a>
                                )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Paper>
              )}
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
