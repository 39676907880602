import React, { Component } from "react";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Card } from "react-bootstrap";
import NavigateNext from "@material-ui/icons/NavigateNext";
import BreadCumb from "../Department/BreadCumb";
import AboutDescription from "./AboutDescription";
import { RightCircleOutlined } from "@ant-design/icons";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";

const list = [
  {
    title: "About",
    link: "/aboutus/about",
  },
  {
    title: "History",
    link: "/aboutus/history",
  },
  {
    title: "Vision & Mission",
    link: "/aboutus/vision",
  },
  {
    title: "Institutional Core Values",
    link: "/aboutus/icv",
  },
  {
    title: "Organogram",
    link: "/aboutus/organogram",
  },
  {
    title: "Certificates",
    link: "/aboutus/certificates",
  },
  {
    title: "Best Practices",
    link: "/aboutus/best-practice",
  },
  {
    title: "Institutional Distingtiveness",
    link: "/aboutus/institutional-distingtiveness",
  },
  {
    title: "Academic Calendar",
    link: "/aboutus/academic-calendar",
  },
  {
    title: "Institutional Development Plan",
    link: "/aboutus/institutional-development-plan",
  },
  {
    title: "Perspective Plan & Deployment",
    link: "/aboutus/perspective-deployment-plan",
  },
  {
    title: "Code of Conduct",
    link: "/aboutus/code-of-conduct",
  },
  // {
  //   title: "You can add more +",
  //   link: "/aboutus/about",
  // },
];

class PanduAbout extends Component {
  state = {
    link: "",
  };
  componentDidMount() {
    let link = this.props.match.params.link;
    this.setState({
      link: link,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.link != this.props.match.params.link) {
      let link = this.props.match.params.link;
      this.setState({
        link: link,
      });
    }
  }


  getLastLink = (link) => {
    let ll = link.split('/');
    if(ll.length > 0){
      return ll[ll.length - 1]
    }else{
      return '';
    }
  }

  render() {
    return (
      <div>
      <BreadCrumbOwn title="About us" />
        <Container className="mb-4">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ paddingTop: "5em" }}
            >
              <Card
                style={{
                  boxShadow: "none",
                  position: "stickey !important",
                  top: "0 !important",
                  backgroundColor: "lightgrey"
                  // backgroundColor: '#EBDB86',
                  // border: `solid 2px #808080`
                }}
              >
                <div
                  style={{
                    boxShadow: "none",
                    position: "stickey",
                    top: "0",
                  }}
                >
                  <List component="nav">
                    {list.map((el, index) => (
                      <ListItem
                        button
                        style={{
                          //borderBottom: "1px solid #0002",
                          marginBottom: "2px",
                          backgroundColor: this.getLastLink(el.link) == this.state.link ? '#EBDB86' : ''
                        }}
                        key={index}
                        onClick={() => this.props.history.push(el.link)}
                      >
                        <div
                          style={{
                            fontSize: "1.1em",
                            fontWeight: "700",
                            padding: "0",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#404040"
                          }}
                        > <RightCircleOutlined />
                        &nbsp;&nbsp;
                          {el.title}
                        </div>
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={9} lg={9} style={{minHeight: '800px', padding: 10}}>
              <AboutDescription type={this.state.link} />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default PanduAbout;
