import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { GetData, PostData } from '../../api/service';
import NotificationDescription from '../Notifications/NotificationDescription';

class AlumniDescription extends Component {
    render() {
        return (
            <div>
                {this.props.type == 'association' ? (<SiteMap1 />) : null}
                {this.props.type == 'committee' ? (<SiteMap />) : null}
                {this.props.type == 'notable' ? (<LandProperty />) : null}
                {this.props.type == 'support' ? (<Building />) : null}
                {this.props.type == 'list' ? (<Building12 />) : null}
                {this.props.type == "students-placed" ? (
          <NotificationDescription
            type="streategies"
            code="COLLEGE_STUDENT_PLACED"
            title="List of Students Placed"
          />
        ) : null}

        {this.props.type == "students-progressed" ? (
          <NotificationDescription
            type="streategies"
            code="COLLEGE_STUDENT_PROGRESSED"
            title="List of Students Progressed"
          />
        ) : null}
            </div>
        );
    }
}

export default AlumniDescription;

export const SiteMap1 = (props) => {
    return (
        <div className="mb-4">
            <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
            Pandu College Alumni Association
            </Typography>

            <div>
  
  <h5>
   
    NAME OF THE ASSOCIATION: Pandu College Alumni Association (PCAA)
  </h5>
  <p>
    <strong>DATE OF ESTABLISHMENT: NOVEMBER 2002</strong>
  </p>
  <p>
    <strong>A BRIEF HISTORY:</strong>
  </p>
  <p>
    Though the college was established in 1962 and as many as 40 batches of
    students passed out of this college, the necessity was never felt to form a
    forum for alumni until the advent of NAAC. It is rather the idea generated
    by this organization that helped in the birth of An Alumni Association of
    Pandu College. A general meeting was called for this purpose in the last
    part of 2002 in the college by the initiative of some teachers who happened
    to be alumni of the college. An Adhoc committee came into existence in the
    month of November 2002. The committee decided to pay more attention to the
    membership drive and collect as many names as possible. It was not an easy
    job to get information about 40 batches of students and however, all
    connection were explored to get linkages who are spread all over the country
    and also in the other parts of the world. After several small meetings, a
    general meeting was organized this year only in the month of November (2003)
    to form a full-fledged committee. This committee has taken some decisions,
    which will be implemented in due course of time. It has yet to place its
    constitution in the general body meeting and get approval.
  </p>
  <p>
    <strong>ACTIVITIES:</strong>
  </p>
  <p>
    It is better to confess frankly that PCAA could not do any things because it
    is still in the teething stage. However, some efforts are there in the
    individual and group level which may be mentioned here to slow our concern.
  </p>
  <ol>
    <li>
      An aluminous doctor is checking the health of hostellers on monthly basis
      for the last two years and rendering his help.
    </li>
    <li>
      &nbsp;Some alumni rendering their help in the form of honorary services in
      teaching and as guest lecture. Mention may be made here of Sri pankaj
      kalita who is talking classes in Chemistry department since September this
      year. Sri Debashish Mitra and Sri Gorapada Biswas were helping the
      Computer Application Department for maintenance of computers.
    </li>
    <li>
      The Chemistry department of this college has formed a sub-committee of its
      alumni and formed a committee. They collected some funds and installed
      fire extinguishers in three laboratories.
    </li>
  </ol>
  <p>
    <strong>PRESENT COMMITTEE:</strong>
  </p>
  <p>
    The permanent committee formed in 23 November 2003 has the following
    composition:-
  </p>
  <table
    style={{ fontFamily: "´Myriad Pro´", width: "100%" }}
    cellSpacing={1}
    cellPadding={3}
    border={0}
    bgcolor="#CCCCCC"
    align="center"
  >
    <tbody>
      <tr>
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>
          <b>Name</b>
        </td>
        <td style={{ border: "1px solid #000000" }}>
          <b>Designation</b>
        </td>
        <td style={{ border: "1px solid #000000" }}>
          <b>Phone No</b>
        </td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>Advisors</td>
        <td style={{ border: "1px solid #000000" }}>Dr Ratneswar Sarma</td>
        <td style={{ border: "1px solid #000000" }}>Former Principal</td>
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Dr. Prabin Ch. Sarma</td>
        <td style={{ border: "1px solid #000000" }}>Former Principal</td>
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Dr. B.C.Das Purkayastha</td>
        <td style={{ border: "1px solid #000000" }}>Principal</td>
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>President</td>
        <td style={{ border: "1px solid #000000" }}>Dr. Prabin Ch.Das</td>
        <td style={{ border: "1px solid #000000" }}>
          Retd. Secretary, University Classes, G.U
        </td>
        <td style={{ border: "1px solid #000000" }}>
          98640-51559/ 0361- 2570538 (R)
        </td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>Working president</td>
        <td style={{ border: "1px solid #000000" }}>Dr Umesh Deka</td>
        <td style={{ border: "1px solid #000000" }}>
          Retd. Head Dept of Folklore, G.U.
        </td>
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>Vice President</td>
        <td style={{ border: "1px solid #000000" }}>Mrs. Bakul Ghose (Dhar)</td>
        <td style={{ border: "1px solid #000000" }}>S.O., N.F.Rly</td>
        <td style={{ border: "1px solid #000000" }}>2570807 (R) 98640-17783</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Ujjal Bhowmick</td>
        <td style={{ border: "1px solid #000000" }}>Ex G.S.</td>
        <td style={{ border: "1px solid #000000" }}>
          2670701(o) 2572811 (R) 98640-89120
        </td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Dulal Bhatta</td>
        <td style={{ border: "1px solid #000000" }}>S.A Pandu College</td>
        <td style={{ border: "1px solid #000000" }}>2571734 (R)</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Kamal Baishya</td>
        <td style={{ border: "1px solid #000000" }}>
          Deputy Commissioner, Kamrup
        </td>
        <td style={{ border: "1px solid #000000" }}>2541797 (R) 98640-34723</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Harendra Ch Das</td>
        <td style={{ border: "1px solid #000000" }}>Social worker</td>
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>General Secretary</td>
        <td style={{ border: "1px solid #000000" }}>Sri Kamalesh Gupta</td>
        <td style={{ border: "1px solid #000000" }}>Ex G.S</td>
        <td style={{ border: "1px solid #000000" }}>2570917</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Ashok Bharali</td>
        <td style={{ border: "1px solid #000000" }}>Ex G.S</td>
        <td style={{ border: "1px solid #000000" }}>
          98640-60924/ 2572057 (R)
        </td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Nabajyoti Patowary</td>
        <td style={{ border: "1px solid #000000" }}>Ex G.S</td>
        <td style={{ border: "1px solid #000000" }}>2850032 (R)</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Binay Medhi</td>
        <td style={{ border: "1px solid #000000" }}>Ex G.S</td>
        <td style={{ border: "1px solid #000000" }}>2841056 (R) 98640-48640</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>
          Mrs AnuradhaBose (Kundu)
        </td>
        <td style={{ border: "1px solid #000000" }}>Lecturer, Pandu College</td>
        <td style={{ border: "1px solid #000000" }}>2471825 (R)</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>Treasurer</td>
        <td style={{ border: "1px solid #000000" }}>Dr. Manoj Sarma</td>
        <td style={{ border: "1px solid #000000" }}>
          Asst. Professor, Pandu College
        </td>
        <td style={{ border: "1px solid #000000" }}>2631787 (R)</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>Members</td>
        <td style={{ border: "1px solid #000000" }}>Sri Subrata Dutta</td>
        <td style={{ border: "1px solid #000000" }}>Councillor, GMC</td>
        <td style={{ border: "1px solid #000000" }}>2571940 (R)</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Chandan Barman</td>
        <td style={{ border: "1px solid #000000" }}>Ex G.S</td>
        <td style={{ border: "1px solid #000000" }}>2570955</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Joydeep Chakraborty</td>
        <td style={{ border: "1px solid #000000" }}>
          Lecturer S.S.A, Guwahati
        </td>
        <td style={{ border: "1px solid #000000" }}>2662941 (R)</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Liladhar Hazarika</td>
        <td style={{ border: "1px solid #000000" }}>
          Head Master, GU Model School
        </td>
        <td style={{ border: "1px solid #000000" }}>2572712 (R)</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Birlang Narzary</td>
        <td style={{ border: "1px solid #000000" }}>Lecturer, Pandu College</td>
        <td style={{ border: "1px solid #000000" }}>94350-11082</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>
          Dr. Dhiraj Bhattacharjee
        </td>
        <td style={{ border: "1px solid #000000" }}>Practising Physician</td>
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Ms Sabita Barua</td>
        <td style={{ border: "1px solid #000000" }}>H.M. Rabindra Vidyapith</td>
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Rajat Das Gupta</td>
        <td style={{ border: "1px solid #000000" }}>Businessman</td>
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Bhakti Dey Tarafder</td>
        <td style={{ border: "1px solid #000000" }}>Sr.Officer, S.B.I, Main</td>
        <td style={{ border: "1px solid #000000" }}>2573107 (R)</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Debashish Ghatak</td>
        <td style={{ border: "1px solid #000000" }}>Office SBI</td>
        <td style={{ border: "1px solid #000000" }}>2670630 (R)</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Amiya Das</td>
        <td style={{ border: "1px solid #000000" }}>N.F.Rly Maligaon</td>
        <td style={{ border: "1px solid #000000" }}>2670546 (R)</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Shyamal Biswas</td>
        <td style={{ border: "1px solid #000000" }}>Columnist &amp; Teacher</td>
        <td style={{ border: "1px solid #000000" }}>2571193 (R)</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Timir Dey</td>
        <td style={{ border: "1px solid #000000" }}>Lecturer Pandu College</td>
        <td style={{ border: "1px solid #000000" }}>2672396 (R)</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Arun Banik</td>
        <td style={{ border: "1px solid #000000" }}>Sri Arun Banik</td>
        <td style={{ border: "1px solid #000000" }}>2574986 (R)</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Udayan Biswas</td>
        <td style={{ border: "1px solid #000000" }}>Editor, Samay Prabaha</td>
        <td style={{ border: "1px solid #000000" }}>2572613 (R)</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Naba Barman</td>
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Pratap Bordoloi</td>
        <td style={{ border: "1px solid #000000" }}>
          News Editor, Guwahati Doordarshan
        </td>
        <td style={{ border: "1px solid #000000" }}>2200119(o)</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Dr Hironmoy Paul</td>
        <td style={{ border: "1px solid #000000" }}>
          Sr.D.M.O, N.F.Rly Central Hospital
        </td>
        <td style={{ border: "1px solid #000000" }}>2670666 (R)</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Dr Dipak Sarkar</td>
        <td style={{ border: "1px solid #000000" }}>Practicing Physician</td>
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Ajit Seal</td>
        <td style={{ border: "1px solid #000000" }}>
          Professor, Govt Arts College
        </td>
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Jayabrata Banerjee</td>
        <td style={{ border: "1px solid #000000" }}>Businessman</td>
        <td style={{ border: "1px solid #000000" }}>98640-16996</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Mrs Anju Bhattacharjee</td>
        <td style={{ border: "1px solid #000000" }}>
          Sr. Lecturer, Pandu College
        </td>
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Sanjib Das</td>
        <td style={{ border: "1px solid #000000" }}>Ex G.S.</td>
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Kanu Lal Paul</td>
        <td style={{ border: "1px solid #000000" }}>
          Regional Manager, Johnson &amp; Johnson
        </td>
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Sri Ranjan Kr. Saharia</td>
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>
          Sri Partha Sarathi Biswas
        </td>
        <td style={{ border: "1px solid #000000" }}>Ex G.S.</td>
        <td style={{ border: "1px solid #000000" }}>2571193 (R)</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
        <td style={{ border: "1px solid #000000" }}>Mrs Mithu Deb Roy</td>
        <td style={{ border: "1px solid #000000" }}>
          Subject Teacher, Bengali Boy´s H.S. School
        </td>
        <td style={{ border: "1px solid #000000" }}>&nbsp;</td>
      </tr>
    </tbody>
  </table>
  <p>
    <strong>PLANS &amp; PROGRAMMES:</strong>
  </p>
  <p>
    The committee adopted some short term and long-term programs for all round
    developments of the college as indicated below. The resource mobilization
    for these purposes will also be taken in due course of time.
  </p>
  <p>
    <strong>Short Term Plans:</strong>
  </p>
  <ul>
    <li>
      Holding Seminars on different subjects of academic and non-academic
      interest
    </li>
    <li>Organizing workshop on Drama, Film appreciation etc</li>
    <li>Health check-up camps for students and employees</li>
    <li>Carrying out awareness programs on oral health &amp; hygiene</li>
    <li>Welfare Fund for Students</li>
    <li>Scholarship to poor &amp; needy students</li>
    <li>Organizing Satya Kinkar Sen Memorial lecture</li>
    <li>Book Bank for College Students</li>
    <li>Felicitation of past teachers of Pandu College</li>
    <li>Help for Physically dis-advantaged students</li>
  </ul>
  <p>
    <br />
    <strong>Long Term Projects:</strong>
  </p>
  <ul>
    <li>Construction of a full-fledged Gymnasium hall in the campus.</li>
    <li>
      Construction of a guest house for the college where alumni will get
      preference
    </li>
    <li>Primary Health centre for the locality</li>
  </ul>
  <p>
    <strong>Recent Activities:</strong>
  </p>
  <p>
    During the Peer Team visit of NAAC in December 2003, PCAA in large numbers
    interacted with the team. It also presented a cultural evening. Performed by
    established artists of fame and alumni, which was appreciated by all. The
    association also hosted a grand dinner party on the evening of 8th December
    2003.
  </p>
  <p>
    Chemistry Alumni segment of the association gifted Fire extinguishers for
    all the Chemistry Laboratories. They formed a separate sub. Committee aiming
    to work for the development of the Chemistry department and enjoy fellowship
    by meeting at least once a year.
  </p>
  <p>
    A health check up cum diabetes detection camp was organized by the
    association on 9th October 2004 for all the employees of Pandu College &amp;
    their family as well. The camp was attended by the alumni doctors Dr.
    Hironmoy Paul, Dr. Dipak Sarkar &amp; Dhiraj Bhattacharjee. All together 87
    persons registered for this camp and got advise from doctors.
  </p>
  <p>
    <strong>Work on Hand:</strong>
  </p>
  <p>
    A blood donation awareness camp was organized in Nov/Dec 2004 and fund
    raising program through musical night is also proposed.
  </p>{" "}
</div>

            </div>
   
    )
}

export const SiteMap = (props) => {
    return (
        <div className="mb-4">
             <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
              Alumni Committee
            </Typography>

            <Typography
                variant="body1"
                gutterBottom
            >
                Committee Advisor: Dr. Bibhash Ch. Das Purkayastha, Dr. Chandana Sinha Roy, Dr. Prabeen Das, Mr. Kamal Baishya, Mr. Kamalesh Gupta <br/>
                President : Mr. Ashok Bharali,<br/> 
                Working President : Dr. Timir Dey,<br/> 
                Vice President : Dr. Manoj Sharma, Mr. Bhabadev Goswami, Dr. Hazarat Ali, Sukla Basak, Sonali Keot Nath, Pran Pratim Chaliha, Dr. Dheeraj Bhattacharya, <br/> 
                General Secretary : Joydeep Chakraborty<br/>
                Joint Secretary : Krishna Kanta Das, Chandan Barman, Chinmoy Sharma, Juthika Patowary<br/>
                
                Treasurer : Dulal Bhatta<br />
                Members: Santanu Roy, Joybrat Banerjee, Sujit Bhattacharya, Chandan Jyoti Das, Atonu Raichoudhury, Rashidar Rahman, Sailen Das, Sekhar Jyoti Goswami, Subrata Choudhury<br />

                Sub-Committee (Spokes Person): Uddayan Biswas, Jadav Mahanta<br />
                Publication & Media: Hemanta Bez<br />
                 

            </Typography>

        </div>
    )
}

export const LandProperty = (props) => {
    return (
        <div className="mb-4">
             <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                Notable Alumni
            </Typography>

            <Typography
                variant="body1"
            >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Typography>
        </div>
    )
}

export const Building = (props) => {
    return (
        <div className='mb-4'>
             <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                Alumni Support
            </Typography>

            <Typography
                variant="body1" style={{textAlign:'justify'}}
            >
                PANDU College Alumni Association has been rendering its services to the infrastructural and holistic development of the college. It built a building named Bidyarthi Bhawan in the college premises and gifted it to the college authority on the occasion of the Silver Jubilee of the college. The Association also donated a drinking water facility, a College Notice Board and offered humanitarian services from time to time. On the occasion of the college Golden Jubilee, the Association built a gorgeous entrance gate with watchman's cabin and offered it to the college to celebrate the golden journey of the college. The core committee of the Association is headed by Dr. Dandeswar Dutta, scientific officer at RFRI, Jorhat. Dr. P.K. Dutta, present Principal of the college is an Advisor and active alumnus. Mr. Jiten Kumar Nath, the present Vice Principal and several other faculty members and office staffs of the college are members of the Association. A huge number of alumni scattered across the globe are members of the Association.
            </Typography>
        </div>
    )
}

export const Building12 = (props) => {

  const [data, setData] = React.useState([])
  const [isLoaded, setIsLoaded] = React.useState(false)

  React.useEffect(() => {
    GetData(`/getdepartments`).then((resp) => {
    setData(resp)
    setIsLoaded(true)
      
    })
  }, [])
  return (
      <div className='mb-4'>
           <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
              Alumni List
          </Typography>


          {isLoaded && <div>
            
            <ul>
              {Array.isArray(data) && data.map((el,index) => <li key={index}>
                  <a style={{fontSize: 14}} targte="_blank" href={`/department/${el.dept_code}/alumni`}>Alumni list of {el.dept_name}</a>
                </li>)}
              </ul>
            </div>}

          
      </div>
  )
}
