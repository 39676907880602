import { Grid } from "@material-ui/core";
import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { PostData } from "../../api/service";

const handleDragStart = (e) => e.preventDefault();

export default function Testimonials() {
  const [readMore, setReadMore] = React.useState(false);

  const [data, setData] = React.useState([]);
  console.log("🚀 ~ Testimonials ~ data:", data)
  const [isLoaded, setIsLoaded] = React.useState(false);

  function __loadData() {
    let d = {
      dept_code: "PANDU",
      type: "COLLEGE_TESTIMONIALS",
    };

    PostData(`/getdepartmentfilebytype`, d).then((resp) => {

      resp.map((el,index) => {
        let haveMore = null;
          // check the length
          let str = removeTags(el.description);
          if (str.length > 400) {
            haveMore = true;
          } else {
            haveMore = null;
          }

          if(haveMore == true){
            resp[index]['haveMore'] = true;
            resp[index]['showMore'] = false;
            resp[index]['str'] = str;
          }

      })

      setData([...resp]);
      setIsLoaded(true);
    });
  }

  React.useEffect(() => {
    __loadData();
  }, []);

  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  const items = [
    <div
      onDragStart={handleDragStart}
      role="presentation"
      style={{
        backgroundColor: "white",
        padding: 20,
        borderRadius: 10,
        margin: 10,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <div
            style={{
              padding: 20,
              fontSize: 16,
              fontWeight: 500,
              color: "black",
              textAlign: "justify",
            }}
          >
            {readMore
              ? `Dear students, distinguished faculty members of Pandu College you have
                come to long way over the fifty years. But many more years we are to
                go. Many outstanding brilliant students have come out of this
                prestigious institute. This college has injected knowledge, skill in
                you which you have developed, which has helped you to build up your
                career. Many of you, ex-students of this college have established
                yourself in your life. Think of your Alma Mater. There are many of the
                Universities, colleges where the ex-students play an important role in
                the development of their Alma Mater. On the completion, I would say
                successful completion of fifty years of your journey, my best wishes
                are with you. But I would like to, of course I would not to live then,
                but I would try to see that this college will scale greater height
                when it will celebrate its Platinum Jubilee after twenty five years,
                when it will celebrate Centenary Year after another fifty years, the
                college will reach on top.`
              : `Dear students, distinguished faculty members of Pandu College you have
                come to long way over the fifty years. But many more years we are to
                go. Many outstanding brilliant students have come out of this
                prestigious institute. This college has injected knowledge, skill in
                you which you have developed, which has helped you to build up your
                career....`}{" "}
            {readMore ? (
              <a style={{ color: "blue" }} onClick={() => setReadMore(false)}>
                Show Less
              </a>
            ) : (
              <a style={{ color: "blue" }} onClick={() => setReadMore(true)}>
                Show More
              </a>
            )}
          </div>
          <div
            style={{
              textAlign: "right",
              fontSize: 16,
              fontWeight: 700,
              color: "black",
            }}
          >
            Pranab Mukherjee
            <br />
            Former President, India
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{ justifyContent: "center", display: "flex", width: "100%" }}
        >
          <img
            style={{ width: "60%", objectFit: "contain" }}
            src={`/images/testimonials/pmm.jpg`}
          />
        </Grid>
      </Grid>
    </div>,
    <div
      onDragStart={handleDragStart}
      role="presentation"
      style={{
        backgroundColor: "white",
        padding: 20,
        borderRadius: 10,
        margin: 10,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <div
            style={{
              padding: 20,
              fontSize: 16,
              fontWeight: 500,
              color: "black",
              textAlign: "justify",
            }}
          >
            I am happy to know that a new College is being inaugurated at Pandu
            thus adding to the facilities for higher education in Assam. For
            progress in higher education the maintenance of high standards is
            essential. I hope that the College will prove to be an asset to the
            State.
          </div>
          <div
            style={{
              textAlign: "right",
              fontSize: 16,
              fontWeight: 700,
              color: "black",
            }}
          >
            Dr. S. Radhakrishnan
            <br />
            RASTRAPATI BHAWAN
            <br />
            NEW DELHI-4
            <br />
            August 21,1962
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{ justifyContent: "center", display: "flex", width: "100%" }}
        >
          <img style={{ width: "60%" }} src={`/images/testimonials/sr.jpeg`} />
        </Grid>
      </Grid>
    </div>,

    <div
      onDragStart={handleDragStart}
      role="presentation"
      style={{
        backgroundColor: "white",
        padding: 20,
        borderRadius: 10,
        margin: 10,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <div
            style={{
              padding: 20,
              fontSize: 16,
              fontWeight: 500,
              color: "black",
              textAlign: "justify",
            }}
          >
            আলোচনীৰ চৰ্তুদ্দশ সংখ্যা প্ৰকাশিত হʼব বুলি জানি আনন্দ পাইছোঁ। সমাজ
            পৰিৱৰ্তনৰ যুগে যুগে কলেজ আলোচনীসমূহে বহুতো অৱদান যোগাই আহিছে। পাণ্ডু
            মহাবিদ্যালয়ৰ ঐতিহ্যৰ সন্মান ৰাখি, ন ন লেখকক জন্ম দি, ছাত্ৰ
            ছাত্ৰীসকলক আশাবাদী কৰি যেন এই সংখ্যাই ভুমুকি মাৰে ------
          </div>
          <div
            style={{
              textAlign: "right",
              fontSize: 16,
              fontWeight: 700,
              color: "black",
            }}
          >
            এই শুভকামনাৰে---
            <br />
            ভূপেন হাজৰিকা
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{ justifyContent: "center", display: "flex", width: "100%" }}
        >
          <img style={{ width: "60%" }} src={`/images/testimonials/bh.jpeg`} />
        </Grid>
      </Grid>
    </div>,
  ];

  return (
    <div>
      <AliceCarousel
      syncStateOnPropsUpdate={false}
        responsive={{
          0: {
            items: 1,
          },
          1024: {
            items: 1,
            itemsFit: "contain",
          },
        }}
        activeIndex={2}
        autoPlay={true}
        autoPlayInterval={3000}
        mouseTracking
        items={data.map((el, index) => {
          
          let url = '/images/office/default.png';

          if(Array.isArray(el.upload_info) && el.upload_info.length > 0){
            if(el.upload_info[0].fileType == "image"){
              url = el.upload_info[0].url;
            }
          }

          return (
            <div
              key={index}
              onDragStart={handleDragStart}
              role="presentation"
              style={{
                backgroundColor: "white",
                padding: 20,
                borderRadius: 10,
                margin: 10,
              }}
            >
           
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <div
                    // dangerouslySetInnerHTML={{
                    //   __html: el.haveMore == true && el.showMore == false
                    //     ? `${el.str.substring(0, 400)}${"..."}`
                    //     : `${el.description}`,
                    // }}
                    dangerouslySetInnerHTML={{
                      __html:`${el.description}`
                    }}
                    style={{
                      padding: 20,
                      fontSize: 16,
                      fontWeight: 500,
                      color: "black",
                      textAlign: "justify",
                    }}
                  ></div>
                  <div style={{ textAlign: "right" }}>
                    {/* {el.haveMore == true && el.showMore == false && (
                      <a
                        onClick={() => {
                          let d = [...data]
                          d[index]["showMore"] = true;
                          setData([...d])
                        }}
                      >
                        Show More
                      </a>
                    )}

                    {el.haveMore == true && el.showMore == true && (
                      <a
                        onClick={() => {
                          let d = [...data]
                          d[index]["showMore"] = false;
                          setData([...d])
                          
                        }}
                      >
                        Show Less
                      </a>
                    )} */}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <img
                    style={{ width: "60%", objectFit: "contain" }}
                    src={url}
                  />
                </Grid>
              </Grid>
            </div>
          );
        })}
      />
    </div>
  );
}
