import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import { MDBContainer } from "mdbreact";

class RtiDescription extends Component {
  render() {
    return (
      <div style={{ marginTop: "" }}>
        {this.props.type == "home" ? (
          <History title="RIGHT TO INFORMATION" />
        ) : null}
        {this.props.type == "officers" ? (
          <Officers title="INFORMATION OFFICERS" />
        ) : null}
        {this.props.type == "code" ? <Code title="CODE OF CONDUCT" /> : null}
        {this.props.type == "act" ? (
          <Act title="RIGHT TO INFORMATION ACT, 2005" />
        ) : null}
        {this.props.type == "ief" ? (
          <Ief title="INFORMATION IN ELECTRONIC FORM" />
        ) : null}
        {this.props.type == "pac" ? (
          <Pac title="PARTICULARS AVAILALE TO THE CITIZENS" />
        ) : null}
      </div>
    );
  }
}

export default RtiDescription;

export const History = (props) => {
  return (
    <div>
      <MDBContainer style={{ paddingTop: "20px" }}>
        <Typography
          variant="h5"
          style={{ margin: "1em 0", fontWeight: 700 }}
          gutterBottom
        >
          {props.title}
        </Typography>
        <hr />

        <Typography variant="body1" gutterBottom>
          Under section 4(1)(b) of Right to Information Act 2005 enacted by
          Indian Parliament, Pandu College Guwahati is publishing all basic
          information of the institute in respect of different functions, duties
          and powers of the authority, teachers, non-teaching staff and code of
          conduct that is followed by its employees while performing duties.
        </Typography>
      </MDBContainer>
    </div>
  );
};

export const Officers = (props) => {
  return (
    <div>
      <MDBContainer style={{ paddingTop: "20px" }}>
        <Typography
          variant="h5"
          style={{ margin: "1em 0", fontWeight: 700 }}
          gutterBottom
        >
          {props.title}
        </Typography>
        <hr />

        <div className="col-md-8">
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginBottom: ".0001pt",
              msoAddSpace: "auto",
              textIndent: "-.25in",
              lineHeight: "normal",
              msoList: "l0 level1 lfo1",
            }}
          >
            {/*[if !supportLists]*/}
            <span
              style={{ fontSize: "12.0pt", fontFamily: "" }}
              times=""
              new=""
            >
              1.
              <span style={{ fontSize: "7pt", lineHeight: "normal" }}>
                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            {/*[endif]*/}
            <span
              style={{ fontSize: "12.0pt", fontFamily: "" }}
              times=""
              new=""
            >
              Dwijen Barman, SA, Pandu College
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginBottom: ".0001pt",
              msoAddSpace: "auto",
              lineHeight: "normal",
            }}
          >
            <span
              style={{ fontSize: "12.0pt", fontFamily: "" }}
              times=""
              new=""
            >
              Pandu, Guwahati-781012
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginBottom: ".0001pt",
              msoAddSpace: "auto",
              lineHeight: "normal",
            }}
          >
            <span
              style={{ fontSize: "12.0pt", fontFamily: "" }}
              times=""
              new=""
            >
              Ph. +91-9435047363
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginBottom: ".0001pt",
              msoAddSpace: "auto",
              lineHeight: "normal",
            }}
          >
            <span
              style={{ fontSize: "12.0pt", fontFamily: "" }}
              times=""
              new=""
            >
              &nbsp;
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginBottom: ".0001pt",
              msoAddSpace: "auto",
              textIndent: "-.25in",
              lineHeight: "normal",
              msoList: "l0 level1 lfo1",
            }}
          >
            {/*[if !supportLists]*/}
            <span
              style={{ fontSize: "12.0pt", fontFamily: "" }}
              times=""
              new=""
            >
              2.
              <span style={{ fontSize: "7pt", lineHeight: "normal" }}>
                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            {/*[endif]*/}
            <strong>
              <u>
                <span
                  style={{ fontSize: "12.0pt", fontFamily: "" }}
                  times=""
                  new=""
                >
                  First Appellate Authority
                </span>
              </u>
            </strong>
            <u>
              <span
                style={{ fontSize: "12.0pt", fontFamily: "" }}
                times=""
                new=""
              ></span>
            </u>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginBottom: ".0001pt",
              msoAddSpace: "auto",
              lineHeight: "normal",
            }}
          >
            <span
              style={{ fontSize: "12.0pt", fontFamily: "" }}
              times=""
              new=""
            >
              Vice-Principal, Pandu College (Ex-Officio)
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginBottom: ".0001pt",
              msoAddSpace: "auto",
              lineHeight: "normal",
            }}
          >
            <span
              style={{ fontSize: "12.0pt", fontFamily: "" }}
              times=""
              new=""
            >
              Pandu, Guwahati-781012
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginBottom: ".0001pt",
              msoAddSpace: "auto",
              lineHeight: "normal",
            }}
          >
            <span
              style={{ fontSize: "12.0pt", fontFamily: "" }}
              times=""
              new=""
            >
              Ph. +91-361-2570450
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginBottom: ".0001pt",
              msoAddSpace: "auto",
              lineHeight: "normal",
            }}
          >
            <span
              style={{ fontSize: "12.0pt", fontFamily: "" }}
              times=""
              new=""
            >
              &nbsp;
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginBottom: ".0001pt",
              msoAddSpace: "auto",
              textIndent: "-.25in",
              lineHeight: "normal",
              msoList: "l0 level1 lfo1",
            }}
          >
            {/*[if !supportLists]*/}
            <span
              style={{ fontSize: "12.0pt", fontFamily: "" }}
              times=""
              new=""
            >
              3.
              <span style={{ fontSize: "7pt", lineHeight: "normal" }}>
                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            {/*[endif]*/}
            <strong>
              <u>
                <span
                  style={{ fontSize: "12.0pt", fontFamily: "" }}
                  times=""
                  new=""
                >
                  Second Appellate Authority
                </span>
              </u>
            </strong>
            <u>
              <span
                style={{ fontSize: "12.0pt", fontFamily: "" }}
                times=""
                new=""
              ></span>
            </u>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginBottom: ".0001pt",
              msoAddSpace: "auto",
              lineHeight: "normal",
            }}
          >
            <span
              style={{ fontSize: "12.0pt", fontFamily: "" }}
              times=""
              new=""
            >
              Dr. Sanchay Jyoti Bora, Principal, Pandu College
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginBottom: ".0001pt",
              msoAddSpace: "auto",
              lineHeight: "normal",
            }}
          >
            <span
              style={{ fontSize: "12.0pt", fontFamily: "" }}
              times=""
              new=""
            >
              Pandu, Guwahati-781012
            </span>
          </p>
          
          {/*EndFragment*/}{" "}
        </div>
      </MDBContainer>
    </div>
  );
};

export const Code = (props) => {
  return (
    <div>
      <MDBContainer style={{ paddingTop: "20px" }}>
        <Typography
          variant="h5"
          style={{ margin: "1em 0", fontWeight: 700 }}
          gutterBottom
        >
          {props.title}
        </Typography>
        <hr />
        <iframe
          src={`/pdf/Code_of_Conduct_pandu.pdf`}
          width="100%"
          height="800px"
        ></iframe>
      </MDBContainer>
    </div>
  );
};

export const Act = (props) => {
  return (
    <div>
      <MDBContainer style={{ paddingTop: "20px" }}>
        <Typography
          variant="h5"
          style={{ margin: "1em 0", fontWeight: 700 }}
          gutterBottom
        >
          {props.title}
        </Typography>
        <hr />
        <iframe
          src={`/pdf/RTI_15jun05.pdf`}
          width="100%"
          height="800px"
        ></iframe>

        <br />

        <iframe
          src={`/pdf/memorandum_RTI.pdf`}
          width="100%"
          height="800px"
        ></iframe>
      </MDBContainer>
    </div>
  );
};

export const Ief = (props) => {
  return (
    <div>
      <MDBContainer style={{ paddingTop: "20px" }}>
        <Typography
          variant="h5"
          style={{ margin: "1em 0", fontWeight: 700 }}
          gutterBottom
        >
          {props.title}
        </Typography>
        <hr />

        <Typography variant="body1" gutterBottom>
          Following information is available in electronic form in office
          computers.
          <br />
          1. Salary, GPF Accounts, CPS Accounts and Income Tax
          <br />
          2. Construction related tender documents
          <br />
          3. Library Collection and Issues
        </Typography>
      </MDBContainer>
    </div>
  );
};

export const Pac = (props) => {
  return (
    <div>
      <MDBContainer style={{ paddingTop: "20px" }}>
        <Typography
          variant="h5"
          style={{ margin: "1em 0", fontWeight: 700 }}
          gutterBottom
        >
          {props.title}
        </Typography>
        <hr />

        <Typography
          style={{ textAlign: "center" }}
          variant="body1"
          gutterBottom
        >
          THE PARTICULARS OF FACILITIES AVAILABLE TO THE CITIZENS FOR OBTAINING
          INFORMATION DURING WORKING HOURS OF THE COLLEGE
        </Typography>

        <Typography variant="body1" gutterBottom>
          a. Working hour:
          <br />
          All the teaching departments, administrative office and central
          library of the college function six days a week from Monday to
          Saturday. Normal working hours of the college is 9 to 5. Library
          facility (reading only) is available to the members of the public. Any
          person may visit the library with the permission of the librarian and
          use the leading room facility on all working days
        </Typography>

        <Typography variant="body1" gutterBottom>
          b. The Citizens can obtain information from the concern departments
          and section of the college <br />
        </Typography>

        <table
          style={{ fontWeight: 500 }}
          className="table table-sm table-bordered"
        >
          <tbody>
            <tr>
              <td width={57}>
                <p>
                  <strong>Sl. No.</strong>
                </p>
              </td>
              <td width={164}>
                <p>
                  <strong>Matters</strong>
                </p>
              </td>
              <td width={269}>
                <p>
                  <strong>Whom to contact during working hours</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td width={57}>
                <p>1</p>
              </td>
              <td width={164}>
                <p>About courses offered by the college</p>
              </td>
              <td width={269}>
                <p>All the Head of the Departments</p>
              </td>
            </tr>
            <tr>
              <td width={57}>
                <p>2</p>
              </td>
              <td width={164}>
                <p>Admission and admission related matter</p>
              </td>
              <td width={269}>
                <p>
                  Coordinator, Admission Committee at the time of admission and
                  College Office any time during working hours
                </p>
              </td>
            </tr>
            <tr>
              <td width={57}>
                <p>3</p>
              </td>
              <td width={164}>
                <p>Recruitment</p>
              </td>
              <td width={269}>
                <p>Principal</p>
              </td>
            </tr>
            <tr>
              <td width={57}>
                <p>4</p>
              </td>
              <td width={164}>
                <p>Purchase/ Construction works</p>
              </td>
              <td width={269}>
                <p>Coordinator, Purchase Committee/Construction Committee</p>
              </td>
            </tr>
            <tr>
              <td width={57}>
                <p>5</p>
              </td>
              <td width={164}>
                <p>Financial Matters</p>
              </td>
              <td width={269}>
                <p>College Accountant</p>
              </td>
            </tr>
            <tr>
              <td width={57}>
                <p>6</p>
              </td>
              <td width={164}>
                <p>Hostels</p>
              </td>
              <td width={269}>
                <p>Superintendents of Boys’/Girls’ Hostel</p>
              </td>
            </tr>
            <tr>
              <td width={57}>
                <p>7</p>
              </td>
              <td width={164}>
                <p>Fees Structure/Free Admission</p>
              </td>
              <td width={269}>
                <p>Admission Committee</p>
              </td>
            </tr>
            <tr>
              <td width={57}>
                <p>8</p>
              </td>
              <td width={164}>
                <p>Students’ Data Base</p>
              </td>
              <td width={269}>
                <p>Coordinator, ICGC</p>
              </td>
            </tr>
            <tr>
              <td width={57}>
                <p>9</p>
              </td>
              <td width={164}>
                <p>Student Welfare</p>
              </td>
              <td width={269}>
                <p>Coordinator, Student Welfare Committee</p>
              </td>
            </tr>
          </tbody>
        </table>
      </MDBContainer>
    </div>
  );
};
