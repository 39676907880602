import React, { Component } from "react";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Card } from "react-bootstrap";
import NavigateNext from "@material-ui/icons/NavigateNext";
import IqacDescription from "./IqacDescription";
import BreadCumb from "../Department/BreadCumb";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";
import { RightCircleOutlined } from "@ant-design/icons";

const list = [
  {
    title: "Strategies",
    link: "/iqac/strategies",
  },
  {
    title: "Functions",
    link: "/iqac/functions",
  },
  {
    title: "IQAC Members",
    link: "/iqac/teams",
  },
  {
    title: "IQAC Self Study Reports",
    link: "/iqac/ssr",
  },
  {
    title: "SSR Matrices",
    link: "/iqac/ssrmatrix",
  },
  {
    title: "AQAR Matrices",
    link: "/iqac/matrix",
  },
  {
    title: "AQAR",
    link: "/iqac/aqar",
  },
  // {
  //   title: "Program Outcomes",
  //   link: "/iqac/pos",
  // },
  
  {
    title: "IQAC Meetings",
    link: "/iqac/atr",
  },
  // {
  //   title: "Meeting Minutes",
  //   link: "/iqac/minutes",
  // },
  // {
  //   title: "Feedback Forms",
  //   link: "/iqac/feedback",
  // },
  // {
  //   title: "Feedback Analysis & Action Taken Report",
  //   link: "/iqac/feedback-analysis",
  // },
  {
    title: "Student Satisfactory Survey",
    link: "/iqac/sss",
  },
  {
    title: "Event / News",
    link: "/iqac/event-news",
  },
  // {
  //   title: "Best Practice",
  //   link: "/iqac/best-practice",
  // },
  // {
  //   title: "Institutional Distingtiveness",
  //   link: "/iqac/institutional-distingtiveness",
  // },
  {
    title: "Annual Report",
    link: "/iqac/annual-report",
  },
  {
    title: "IQAC Newsletter",
    link: "/iqac/iqac-newsletter",
  },
  {
    title: "MOUs & Collaborations",
    link: "/iqac/mous-collaborations",
  },
  // {
  //   title: "Academic Calendar",
  //   link: "/iqac/academic-calendar",
  // },
  // {
  //   title: "Organogram",
  //   link: "/iqac/organogram",
  // },
];


class IqacComponent extends Component {
  state = {
    link: "",
  };
  componentDidMount() {
    let link = this.props.match.params.link;
    this.setState({
      link: link,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.link != this.props.match.params.link) {
      let link = this.props.match.params.link;
      this.setState({
        link: link,
      });
    }
  }


  getLastLink = (link) => {
    let ll = link.split('/');
    if(ll.length > 0){
      return ll[ll.length - 1]
    }else{
      return '';
    }
  }

  render() {
    return (
      <div>
      <BreadCrumbOwn title="Internal Quality Assesment Cell" />
        <Container>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ paddingTop: "5em" }}
            >
              <Card
                style={{
                  boxShadow: "none",
                  position: "stickey !important",
                  top: "0 !important",
                  backgroundColor: "lightgrey"
                }}
              >
                <div
                  style={{
                    boxShadow: "none",
                    position: "stickey",
                    top: "0",
                  }}
                >
                  <List component="nav">
                    {list.map((el, index) => (
                      <ListItem
                        button
                        style={{
                          //borderBottom: "1px solid #0002",
                          marginBottom: "2px",
                          backgroundColor: this.getLastLink(el.link) == this.state.link ? '#EBDB86' : ''
                        }}
                        key={index}
                        onClick={() => this.props.history.push(el.link)}
                      >
                        <div
                           style={{
                            fontSize: "1.1em",
                            fontWeight: "700",
                            padding: "0",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#404040"
                          }}
                        >
                        <RightCircleOutlined />
                        &nbsp;&nbsp;
                          {el.title}
                        </div>
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={9} lg={9}>
              <IqacDescription type={this.state.link} />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default IqacComponent;
