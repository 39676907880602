import React from "react";
import { CardActionArea, Grid, Typography } from "@material-ui/core";
import SubscriptionModal from "../Essentials/SubscriptionModal";
import { FacebookFilled, TwitterSquareFilled } from "@ant-design/icons";
import { Counter } from "../Essentials/WebCounter";

export default function PanduFooter() {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  return (
    <div>
      {/* <SubscriptionModal isModalVisible={isModalVisible}  setIsModalVisible={setIsModalVisible}/> */}
      <div className="footer p-4">
        {/* <MDBContainer> */}
        <div className="row">
          <div
            className="col-md-3"
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={"/images/logo.png"}
              alt="logo"
              style={{ width: "140px", padding: "0" }}
            />
            <div
              className="navbar-div-text"
              style={{
                flexBasis: "auto",
                margin: "15px",
                padding: "0 10px",
                borderRight: "3px solid #251d25",
              }}
            >
              <Typography className="foot1h" variant="h5">
                PANDU COLLEGE
              </Typography>
              {/* <Typography className="foot1h" variant="h5">
                পাণ্ডু মহাবিদ্যালয়{" "}
              </Typography> */}
              <img
                src="/images/pandu.png"
                style={{
                  maxWidth: "14em",
                  background: "#EAF3EB00",
                  filter: "invert(1)",
                }}
                alt="pandu College"
              />

              <div className="mt-4 add">
                <div className="h6" style={{ color: "white" }}>
                  Address
                </div>
                <p style={{ color: "white" }}>
                  Pandu College, Pandu <br />
                  Guwahati, Kamrup Metropolitan
                  <br />
                  PIN-781 012 <br />
                  Assam, India.
                </p>
                <p>0361 - 2570450, 0361 - 2673698</p>
                <p>info@panducollege.ac.in</p>
              </div>
            </div>
          </div>

          <div className="col-md-3 mt-4 add">
            <div className="h6" style={{ fontSize: 20 }}>
              Quick Links
            </div>

            <ul style={{ listStyleType: "none", marginLeft: "-30px" }}>
              <li>
                <a
                  target="_blank"
                  href="https://www.iitg.ac.in/"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  IITG
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="http://ignou.ac.in/"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  IGNOU
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://gauhati.ac.in/"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Gauhati University
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="http://gucdoesrm.in/gucdoeadmission/login/auth"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  GUCDOE
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://kkhsou.ac.in/"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  KKHSOU
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="http://naac.gov.in/index.php/en/"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  NAAC
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="http://directorateofhighereducation.assam.gov.in/"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Directorate of Higher Education
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://www.ugc.gov.in/"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  University Grant Commission
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://nptel.ac.in/"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  NPTEL
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://www.nationallibrary.gov.in/"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  National Library
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://nta.ac.in/"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  NTA
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://sletne.org/"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  SLET
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://upsc.gov.in/"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  UPSC
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://apsc.nic.in/"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  APSC
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://aishe.gov.in/aishe/"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  AISHE
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-3 mt-4 add">
            <div className="h6" style={{ fontSize: 20 }}>
              Important Pages
            </div>

            <ul style={{ listStyleType: "none", marginLeft: "-30px" }}>
              <li>
                <a
                  target="_blank"
                  href="/alumni/committee"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Alumni Assocation
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="/iqac/strategies"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  IQAC
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://directorateofhighereducation.assam.gov.in/"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  DHE Updates
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://rusa.nic.in/"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  RUSA
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://scholarships.gov.in/"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  NSP
                </a>
              </li>

              {/* <li>
              <a target="_blank" href="" style={{color: "white", fontWeight: 700}}>Gallery</a>
              </li> */}

              <li>
                <a
                  target="_blank"
                  href="/students/downloads"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Downloads
                </a>
              </li>

              {/* <li>
              <a target="_blank" href="" style={{color: "white", fontWeight: 700}}>College Magazine</a>
              </li> */}

              <li>
                <a
                  target="_blank"
                  href="/iqac/academic-calendar"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Academic Calender
                </a>
              </li>

              {/* <li>
              <a target="_blank" href="" style={{color: "white", fontWeight: 700}}>Holiday List</a>
              </li> */}
            </ul>
          </div>

          <div className="col-md-3 mt-4">
            <div className="form-group">
              <div className="h6" style={{ fontSize: 20 }}>
                Total Visitors
              </div>
              <Counter />

              <br />
              <br />
              <br />
              <br />
              <br />

              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1790.4928136632263!2d91.68988095650575!3d26.1645975516189!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a5b1c917bdba1%3A0xb5ba898835797d7f!2sPandu%20College!5e0!3m2!1sen!2sin!4v1702385915538!5m2!1sen!2sin"
                width="100%"
                height="200"
                style={{ border: 0 }}
                allowFullscreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
        {/* </MDBContainer> */}
      </div>

      <div
        className="row px-4 py-2 bg-violet d-flex"
        style={{
          background: "#000",
          justifyContent: "space-between",
          margin: "0",
        }}
      >
        <div className="text-white font-weight-light">©2023 Pandu College</div>
        <div className="text-white font-weight-light">
          Designed & Developed With &#x2764; by Corexx
        </div>
      </div>
    </div>
  );
}
