import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import PrincipalContainer from "./PrincipalContainer";
import VicePrincipalContainer from "./VicePrincipalContainer";
import GoverningBody from "./GoverningBody";

import { MDBContainer } from "mdbreact";
import { PostData } from "../../api/service";
import NotificationDescription from "../Notifications/NotificationDescription";

class AdministrationDescription extends Component {
  render() {
    return (
      <div style={{ marginTop: "" }}>
        {this.props.type == "principal" ? <PrincipalContainer /> : null}
        {this.props.type == "vice-principal" ? <VicePrincipalContainer /> : null}
        {this.props.type == "gb" ? <GoverningBody /> : null}
        {this.props.type == "officestaff" ? <OfficeStaff /> : null}
        {this.props.type == "govtdocuments" ? <NotificationDescription type="streategies" code="COLLEGE_GOVT_DOC" title="Government Documents" /> : null}
      </div>
    );
  }
}

export default AdministrationDescription;

export const OfficeStaff = () => {
  const [data, setData] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);

  function loadData() {
    let d = {
      dept_code: "PANDU",
      type: "OFFICE_STAFF",
    };

    PostData(`/getemployeebytype`, d).then((resp) => {
      setData(resp);
      setIsLoaded(true);
    });
  }


  React.useEffect(() => {
    loadData()
  }, [])

  return (
    <div>
      <div style={{ paddingTop: "20px" }}>
        <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
          Office Staff
        </Typography>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell style={{fontWeight: 700}}>Name</TableCell>
              <TableCell style={{fontWeight: 700}}>Designation</TableCell>
              <TableCell style={{fontWeight: 700}}>Contact</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(data) &&
              data.map((el, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {Array.isArray(el.dp) && el.dp.length> 0 && <img
                      src={el.dp[0]}
                      style={{width: 100}}
                     />}
                  </TableCell>
                  <TableCell>{el.name}</TableCell>
                  <TableCell>{el.designation}</TableCell>
                  <TableCell>{el.email}<br />{el.phone}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
