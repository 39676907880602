import React, { Component } from "react";
import "./css/homepagenews.css";
import { PostData } from "../../api/service";
import { Link, Typography } from "@material-ui/core";
import Marquee from "react-easy-marquee";
import NewMarquee from "./NewMarquee";

export default class HomePageNews extends Component {
  state = {
    news: [],
    isLoaded: false,
  };

  componentDidMount() {
    // perform the api call

    let d = {
      type: this.props.type,
      dept_code: this.props.dept_code || "PANDU",
      year: ""
    };

    PostData(`/getnoticesbytypedeptv1`, d).then((resp) => {
      this.setState({
        news: resp,
        isLoaded: true,
      });
      console.log(resp);
    });
  }



  render() {
    return (
      <div>
        <div>
          {console.log(this.state.news)}
          {/* {this.state.isLoaded ? (<NewMarquee
                        height={250}
                        initialSpeed={50}
                    >
                        {this.state.news.map((el, index) => {
                        
                            return (
                                <Link to={el.link} key={index}>
                                    <NewsCard
                                        {...el}
                                      
                                    />
                                </Link>
                            )
                        })}
                    </NewMarquee>) : null} */}
                     {this.state.isLoaded ? (<marquee style={{width: '100%', height: '500px'}} scrolldelay="2" scrollamount="2" direction="up"  gradient={false}>
                        {this.state.news.map((el, index) => {
                        
                            return (
                                <Link to={el.link} key={index}>
                                    <NewsCard
                                        {...el}
                                      
                                    />
                                </Link>
                            )
                        })}
                    </marquee>) : null}
          {/* {this.state.isLoaded ? (
            <Marquee
              duration={2000}
              height="200px"
              width="100%"
              axis="Y"
              align="left"
              pauseOnHover={true}
              reverse={false}
              autoplay={false}
            >
              {this.state.news.map((el, index) => {
                return (
                  <Link href={el.link} key={index}>
            
                    <NewsCard {...el} />
                  </Link>
                );
              })}
            </Marquee>
          ) : null} */}
        </div>
      </div>
    );
  }
}

const NewsCard = (props) => {

  return (
    <div
      style={{
        marginBottom: "5px",
        backgroundColor: "transparent",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{width:'75%'}}>
          <Typography variant="caption" style={{ color: "#000E" }}>
            {props.date_no} - {props.month} {props.year}
          </Typography>
          <Typography variant="body2" style={{ color: "#B94735" }}>
            {props.title}
          </Typography>
        </div>
       
        {props.diff && (
          <img
            src="/images/icons/new-blink-pic.gif"
            style={{ width: "40px" }}
          />
        )}
        
      </div>
    </div>
  );
};
