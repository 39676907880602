import { Container } from "@material-ui/core";
import React from "react";

export default function BreadCrumbOwn({title, subtitle}) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "120px",
          backgroundImage:
            "url('https://panducollege.ac.in/gallery/images/pc-1.jpg')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundColor: "black",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            background: `linear-gradient(to bottom, transparent 0%, rgba(0,0,0, .7) 0%)`,
            width: "100%",
            minHeight: "100%",
          }}
        >
          &nbsp;
        </div>

        <div
          style={{
            color: "white",
            fontWeight: 700,
            fontSize: 28,
            position: "absolute",
            bottom: 10,
            left: 0,
            width: "100%",
          }}
        >
          <Container>
            <div>{title}</div>
            <div>{subtitle}</div>
          </Container>
        </div>
      </div>
    </div>
  );
}
