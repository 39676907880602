import React,{ Component } from "react";
import { PostData } from "../../api/service";
import { Table, TableCell, TableRow, TableHead, TableBody, Typography, Paper, Card } from "@material-ui/core";

export default class CommiteeCode extends Component {

    constructor(props){
        super(props);
        this.state = {
            committees: [],
            isLoaded: false,
            commitee_code: ''
        }
    }
    

    componentDidMount(){
        // we will load the committee
        PostData(`/getcommitteebycode`, {committee_code: this.props.committee_code})
        .then((resp) => {
            this.setState({
                committees: resp,
                isLoaded: true
            })
        })
    }

    render(){
        return(
            <div style={{paddingTop: 40}}>
                        {this.state.isLoaded && this.state.committees.map((el,index) => 
                        <Card style={{marginBottom: '20px'}} raised>
                        <Table   size="small" key={index}>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan="3" align="center"><Typography variant="h6">
                                        {el.name}
                                        </Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Designation</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {el.members.map((el1,index1) => 
                                <TableRow key={index}>
                                    <TableCell>{index1+1}</TableCell>
                                    <TableCell>{el1.member_name}</TableCell>
                                    <TableCell>{el1.member_designation}</TableCell>
                                </TableRow>
                            )}
                            </TableBody>
                        </Table>
                        </Card>
                        )}
            </div>
        )
    }
}