import React, { Component } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import BreadCumb from "./../component/Department/BreadCumb";
import NotificationDescription from "../component/Notifications/NotificationDescription";
import {
  Container,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { PostData } from "../api/service";
import { Button } from "react-bootstrap";
import ShowImage from "../component/Department/ShowImage";
import { RightCircleOutlined } from "@ant-design/icons";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";

const list = [
  {
    year: 2024,
  },
  {
    year: 2023,
  },
  {
    year: 2022,
  },
  {
    year: 2021,
  },
  {
    year: 2020,
  },
  {
    year: 2019,
  },
];

export default class Events extends Component {
  state = {
    data: [],
    isLoaded: false,
    year: "",
  };

  __loadFile = (year) => {
    let d = {
      dept_code: "PANDU",
      type: "COLLEGE_EVENT",
      year: year,
    };

    PostData(`/getnoticesbytypedept`, d).then((resp) => {
      this.setState({
        data: resp,
        isLoaded: true,
      });
    });
  };

  componentDidMount() {
    this.__loadFile();
  }

  setYear = (year) => {
    this.__loadFile(year);
  };

  render() {
    return (
      <div style={{ minHeight: "500px" }}>
       <BreadCrumbOwn title="Events" />
        <Container>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ paddingTop: "5em" }}
            >
              <Card
                style={{
                  boxShadow: "none",
                  background: "",
                  borderRadius: "none",
                  backgroundColor: "lightgrey",
                }}
              >
                <Typography
                  variant="h6"
                  style={{ margin: "1em 0", paddingLeft: 10 }}
                  gutterBottom
                >
                  Event Archives
                </Typography>
                <List component="nav">
                  {list.map((el, index) => (
                    <ListItem
                      button
                      style={{
                        //borderBottom: "1px solid #0002",
                        marginBottom: "2px",
                      }}
                      key={index}
                      onClick={() => this.setYear(el.year)}
                    >
                      <div
                        style={{
                          fontSize: "1.1em",
                          fontWeight: "700",
                          padding: "0",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#404040",
                        }}
                      >
                        <RightCircleOutlined />
                        &nbsp;&nbsp;
                        {el.year}
                      </div>
                    </ListItem>
                  ))}
                </List>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={9} lg={9}>
              <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
                {this.props.title}
              </Typography>
              <br />
              {this.state.isLoaded ? (
                <Grid container spacing={2}>
                  {this.state.data.map((el, index) => (
                    <Grid item key={index} xs={12} sm={12} lg={12} md={12}>
                      <Card>
                        <ShowImage
                          files={el.upload_info}
                          nos="1"
                          height="100%"
                          width="100%"
                        />
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            {el.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            dangerouslySetInnerHTML={{ __html: el.description }}
                          ></Typography>
                        </CardContent>

                        {Array.isArray(el.upload_info) ? (
                          <CardActions>
                            {el.upload_info.map((el1, index1) => (
                              <Button
                                size="small"
                                key={index1}
                                //onClick={this.handleClick.bind(this, el1)}
                                color="primary"
                              >
                                Attachment {index1 + 1}
                              </Button>
                            ))}
                          </CardActions>
                        ) : null}
                      </Card>
                    </Grid>
                  ))}

                  {}

                  {this.state.data.length === 0 ||
                  this.state.data.length == "undefined" ? (
                    <Typography>No Events found</Typography>
                  ) : null}
                </Grid>
              ) : (
                <Typography>Loading . . . </Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
