import React from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import "./PanduNav.css";
import SubNav3 from "./SubNav3";
import { NavLink, MDBBtn } from "mdbreact";
import { Link } from "react-router-dom";
import { Typography, Grid, Button } from "@material-ui/core";
import {
  FacebookFilled,
  TwitterSquareFilled,
  YoutubeFilled,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import Search from "antd/lib/input/Search";
const { Header, Content, Footer } = Layout;
export default class PanduNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  //   handleScroll = () => {
  //     if (window.scrollY < 150) {
  //       this.setState({
  //         sticky: false
  //       })
  //     } else if (window.scrollY > 150) {
  //       this.setState({
  //         sticky: true
  //       })
  //     }
  //   }

  render() {
    return (
      <div>
        <div className="m-none">
          <div className="top-nav-top">
            <div className="top-nav-top-email">
              <a
                style={{ color: "white", fontWeight: 700 }}
                href="mailto:principal@panducollege.ac.in"
              >
                principal@panducollege.ac.in
              </a>
            </div>

            <div className="top-nav-top-menu">
              <Menu
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={["2"]}
                onClick={(e) => {
                  this.props.history.push(e.key);
                }}
                style={{ lineHeight: "40px", backgroundColor: "#E46D25" }}
              >
                <Menu.Item key="/nirf" className="top-nav-top-menu-item">
                  NIRF
                </Menu.Item>
                <Menu.Item key="/aishe" className="top-nav-top-menu-item">
                  AISHE
                </Menu.Item>
                <Menu.Item
                  key="/grievances"
                  className="top-nav-top-menu-item"
                >
                  Grievances
                </Menu.Item>
                <Menu.Item key="/rti/home" className="top-nav-top-menu-item">
                  RTI
                </Menu.Item>
                <Menu.Item
                  key="/alumni/association"
                  className="top-nav-top-menu-item"
                >
                  Alumni
                </Menu.Item>
                <Menu.Item key="/contactus" className="top-nav-top-menu-item">
                  Contact us
                </Menu.Item>
              </Menu>
            </div>
            <div className="top-nav-top-social">
              <a
                style={{ color: "white" }}
                href="https://www.facebook.com/profile.php?id=100093404006664&mibextid=ZbWKwL"
                target="_blank"
              >
                <FacebookFilled style={{ fontSize: 20 }} />
              </a>
              <a
                style={{ color: "white" }}
                href="https://twitter.com/PanduCollege?t=qfj9Iea5IaO0Wxrj5zlJAg&s=08"
                target="_blank"
              >
                <TwitterSquareFilled style={{ fontSize: 20 }} />
              </a>
              <YoutubeFilled style={{ fontSize: 20 }} />
            </div>
            <div className="top-nav-top-search">
              <Search
                placeholder="Search Here . . . "
                onSearch={(value) => console.log(value)}
                style={{ width: `100%` }}
              />
            </div>
          </div>

          <Navbar expand="md" className="main-nav-class">
            <div className="main-nav-class-div-1">
              <a href="/">
                <img className="logo-img" src={"/images/logo.png"} alt="logo" />
              </a>
            </div>
            <div
              className="navbar-div-text"
              style={
                {
                  // margin: "15px",
                  // padding: "0 10px",
                  // borderRight: "3px solid #251d25",
                }
              }
            >
              <Typography className="nav-bar-div-text-h" variant="h4">
                <img
                  src="/images/pandu-text.png"
                  className="pandu-text-class"
                  alt="pandu College1"
                />
              </Typography>
              <Typography className="nav-bar-div-text-h" variant="h1">
                Pandu College
              </Typography>
              {/* <Typography className="nav-bar-div-text-h" variant="h4">
                {this.props.data != undefined ? (
                  <span>{this.props.data.dept_name}</span>
                ) : (
                  <img
                    src="/images/pandu-text.png"
                    className="pandu-text-class"
                    alt="pandu College1"
                  />
                )}
              </Typography>
              <Typography className="nav-bar-div-text-h" variant="h1">
                {this.props.data != undefined ? (
                  <span>{this.props.data.dept_name_as}</span>
                ) : (
                  "Pandu College"
                )}
              </Typography> */}

              <div style={{ fontWeight: 700, fontSize: 14 }}></div>
            </div>
            <div className="main-nav-class-div-2">
              Re-accredited by NAAC  <br />
              under Revised Accreditation Framework (RAF)
              <br />
              An ISO 9001-2015 Certified Institution
            </div>
            <div className="header-image-div">
              <img
                className="header-image-div-item"
                src="/images/icons/pandu-g20.png"
              />

              <img
                className="header-image-div-item"
                src="/images/icons/pandu-aam.png"
              />

              <img
                className="header-image-div-item"
                src="/images/icons/pandu-60.png"
              />
            </div>
            <br />
          </Navbar>
        </div>

        {/* for mobile */}
        <div className="m-display">
          <Navbar expand="md" className="main-nav-class m-display">
            <a href="/">
              <img className="logo-img" src={"/images/logo.png"} alt="logo" />
            </a>
            <div
              className="navbar-div-text"
              style={{
                flexBasis: "auto",
                margin: "15px",
                padding: "0 10px",
              }}
            >
            <Typography className="nav-bar-div-text-h" variant="h1">
               
                  PANDU COLLEGE
                
              </Typography>
              <Typography className="nav-bar-div-text-h" variant="h4">
                
                  <img
                    src="/images/pandu.png"
                    style={{ width: "6em" }}
                    alt="pandu College1"
                  />
                
              </Typography>
              {/* <Typography className="nav-bar-div-text-h" variant="h1">
                {this.props.data != undefined ? (
                  <span>{this.props.data.dept_name_as}</span>
                ) : (
                  "PANDU COLLEGE"
                )}
              </Typography>
              <Typography className="nav-bar-div-text-h" variant="h4">
                {this.props.data != undefined ? (
                  <span>{this.props.data.dept_name}</span>
                ) : (
                  <img
                    src="/images/pandu.png"
                    style={{ width: "6em" }}
                    alt="pandu College1"
                  />
                )}
              </Typography> */}
            </div>
          </Navbar>
        </div>

        <SubNav3
          sticky={this.state.sticky}
          width={`200px`}
          color={`#1E3760`}
          backgroundOfNav={`white`}
          backgroundColor={`#1E3760`}
          sticky={this.state.sticky}
          textColor={`white`}
          history={this.props.history}
        />
      </div>
    );
  }
}
