import React, { Component } from "react";

import { MDBContainer } from "mdbreact";
import { Card, CardHeader } from "reactstrap";
import { Typography } from "@material-ui/core";
import BreadCumb from "../Department/BreadCumb";

export default class GoverningBody extends Component {
  render() {
    return (
      <div>
        <div style={{ paddingTop: "20px" }}>
          <br />

          <div>
            <Card style={{ boxShadow: "none" }}>
              <CardHeader
                style={{
                  color: "white",
                  boxShadow: "none",
                  backgroundColor: "#B94735",
                  textAlign: "center",
                }}
              >
                <h3 style={{ color: "inherit" }}>Governing Body</h3>
              </CardHeader>
            </Card>

            <br />

            <Typography variant="h6" gutterBottom>
              The present Governing Body of the College is Consisted of the
              following Members:
            </Typography>

            <Typography variant="body1" gutterBottom>
              Dr. B. C. Das Purkayastha
              <br />
              President
              <br />
              <br />
              Dr. Sanchay Jyoti Bora
              <br />
              Secretary, Principal Pandu College,
              <br />
              <br />
              Prof. Prasanta Kumar Saikia
              <br />
              G. U. Representative
              <br />
              <br />
              Prof. Rudra Kanta Deka
              <br />
              G. U. Representative
              <br />
              <br />
              Dr. Ruma Medhi
              <br />
              Teacher Representative
              <br />
              <br />
              Dr. Mrinal Ch. Kalita
              <br />
              Teacher Representative
              <br />
              <br />
              Shri Dilip Sarma
              <br />
              Non-Teaching Representatives
              <br />
              <br />
              Sri Partha Pratim Sarma
              <br />
              Ex-Officio Member
              <br />
              <br />
              Mrs. Pratima Nath
              <br />
              Guardian Nominee
              <br />
              <br />
              Sri Jadab Goswami
              <br />
              Guardian Nominee
              <br />
              <br />
              Sri Bimal Chandra Das
              <br />
              Guardian Nominee
              <br />
              <br />
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}
