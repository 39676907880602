import React, { Component } from "react";
import {
  Typography,
  Container,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { MDBContainer } from "mdbreact";
import NotificationDescription from "../Notifications/NotificationDescription";

class FeedbacksDescription extends Component {
  render() {
    return (
      <div style={{ marginTop: "" }}>
        {this.props.type == "home" ? <History title="Feedback Links" /> : null}
        {this.props.type == "feedback-analysis" ? <NotificationDescription type="streategies" code="IQAC_FEEDBACK_ANALYSIS" title="Feedback Analysis & Action Taken Report" /> : null}
      </div>
    );
  }
}

export default FeedbacksDescription;

export const History = (props) => {
  return (
    <div>
      <Container style={{ paddingTop: "20px" }}>
        <Typography
          variant="h5"
          style={{ margin: "1em 0", fontWeight: 700 }}
          gutterBottom
        >
          {props.title}
        </Typography>
        <hr />

        <Paper>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 700 }}>Type</TableCell>
                <TableCell style={{ fontWeight: 700 }}>Link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Alumni Feedback Form</TableCell>
                <TableCell>
                  <a
                    target="_blank"
                    href={`https://forms.gle/ef4cMjpdshSxmgQr6`}
                  >
                    Click here
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Teachers’ Feedback Form</TableCell>
                <TableCell>
                  <a
                    target="_blank"
                    href={`https://forms.gle/wizEBNcNeXE3rd5c7`}
                  >
                    Click here
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Employers Feedback Form</TableCell>
                <TableCell>
                  <a
                    target="_blank"
                    href={`https://forms.gle/fnfRagU5TBPrk3nF8`}
                  >
                    Click here
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Students’ Feedback Form</TableCell>
                <TableCell>
                  <a
                    target="_blank"
                    href={`https://forms.gle/zYJ7sKEoQf7RrZhz8`}
                  >
                    Click here
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Parents’ Feedback Form</TableCell>
                <TableCell>
                  <a
                    target="_blank"
                    href={`https://forms.gle/p5a89dZVS6zoCcVh8`}
                  >
                    Click here
                  </a>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Students’ Grievances Form</TableCell>
                <TableCell>
                  <a
                    target="_blank"
                    href={`https://forms.gle/odRQ86uxKVgdGBRk9`}
                  >
                    Click here
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Employee’s Grievance Form</TableCell>
                <TableCell>
                  <a
                    target="_blank"
                    href={`https://forms.gle/Eorm4pWAvv37N29M6`}
                  >
                    Click here
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Students’ Grievances, Feedback and Suggestion Form
                </TableCell>
                <TableCell>
                  <a
                    target="_blank"
                    href={`https://forms.gle/CFUsAP6nyig9QCii6`}
                  >
                    Click here
                  </a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Container>
    </div>
  );
};
