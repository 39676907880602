import { Fab, makeStyles, Zoom } from '@material-ui/core'
import React from 'react'



const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#F89E2E',
        color: 'white',
        "&:hover":  {
            backgroundColor: 'white',
            color: '#F89E2E'
        }
    }
}))

export default function FloatingAdmission({ session }) {

    const classes = useStyles();

  return (
    <React.Fragment>

        <Zoom in={true} timeout={500}>
      <div
        style={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          zIndex: 999,
        }}
      >
        <Fab
          variant="extended"
          className={classes.root}
          href={`https://admission.panducollege.ac.in`}
          target="_blank"
        >
          Online Admission ({session})
        </Fab>

      </div>
      </Zoom>
    </React.Fragment>
  )
}
