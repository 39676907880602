import React, { Component, useState, useEffect } from "react";

import { MDBContainer } from "mdbreact";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Container, Typography, Grid } from "@material-ui/core";
import BreadCumb from "../Department/BreadCumb";
import { GetData, PostData } from "../../api/service";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";

export default class ListOfMembers extends Component {
  state = {
    data: [],
    isLoaded: false,
  };

  componentDidMount() {
    PostData(`/getalumnibydept`, {
      dept_code: "PCSU",
    }).then((resp) => {
      this.setState({
        data: resp,
        isLoaded: true,
      });
    });
  }

  render() {
    return (
      <div>
        <div>
          <Typography
            variant="h5"
            style={{ margin: "1em 0", fontWeight: 700 }}
            gutterBottom
          >
            PCSU Members
          </Typography>

          <br />

          <div>
            {this.state.isLoaded && (
              <>
                <Grid container spacing={2}>
                  {this.state.data.map((el, index) => (
                    <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                      <Card>
                        <CardBody
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {Array.isArray(el.dp) &&
                              el.dp.map((el1) => (
                                <img src={el1.url} style={{ width: 150, objectFit: "cover" }} />
                              ))}
                          </div>
                          <div
                            style={{
                              paddingLeft: 20,
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="h6"
                              style={{ fontWeight: 700 }}
                              
                            >
                              {el.name}
                            </Typography>
                            
                            <Typography
                              variant="body2"
                              style={{ fontWeight: 700 }}
                              
                            >
                              {el.batch}
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{ fontWeight: 700 }}
                            
                            >
                              {el.current_profession}
                            </Typography>
                            <br />
                            {el.email && <><Typography variant="overline">CONTACT</Typography>
                            <Typography variant="body1">
                              <a href={`mailto:${el.email.toLowerCase()}`}>
                                {el.email.toLowerCase()}
                              </a> &nbsp;  <a href={`tel:${el.phone.toLowerCase()}`}>
                                {el.phone.toLowerCase()}
                              </a>
                            </Typography></>}

                           
                          </div>
                        </CardBody>
                      </Card>
                      <br />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
